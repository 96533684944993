import { ThemeProvider } from '@sparemin/blockhead';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';
import Helmet from 'components/Helmet';
import { darkTheme } from 'theme';
import LazyRouteFallback from './LazyRouteFallback';

export interface LazyThemedPageProps {
  children?: React.ReactNode;
  theme?: DefaultTheme;
}

/**
 * For lazy routes that have to render in a different theme than the default.
 * This component provides both the theme provider and the Suspense component
 * to render the lazy route.
 *
 * The LazyRouteFallback component uses the theme to color the page background during
 * loading of async chunks.  If the page has a custom theme and its ThemeProvider
 * isn't above the LazyRouteFallback, the loading screen will render in the default
 * theme while the rest of the route renders in the custom theme.
 * This component fixes that.
 */
const LazyThemedPage: React.FC<LazyThemedPageProps> = ({
  children = <Outlet />,
  theme = darkTheme,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <style id="bg">
          {`
        body {
          background-color: ${theme.backgroundColorMain};
        } 
      `}
        </style>
      </Helmet>
      <Suspense fallback={<LazyRouteFallback />}>{children}</Suspense>
    </ThemeProvider>
  );
};

export default LazyThemedPage;
