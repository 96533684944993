import { useContext } from 'react';
import { FeatureDataContext } from './FeatureDataContext';
import { FeatureDataContextType } from './types';

export function useFeatureData<TData = undefined>() {
  const context = useContext(FeatureDataContext);

  if (context === undefined) {
    throw new Error('useFeatureData must be used within FeatureDataProvider');
  }

  return context as FeatureDataContextType<TData>;
}
