import { GradientIcon } from '@sparemin/blockhead';
import React from 'react';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface AutomatedTranscriptionsContentsProps extends BaseUpsellModalProps {}

const AutomatedTranscriptionsContents: React.FC<
  AutomatedTranscriptionsContentsProps
> = () => {
  return (
    <BaseUpsellModal
      title="Get transcriptions automatically"
      subtitle="You’re currently on Headliner’s Forever Free plan. Upgrade today to link your podcast and automatically get a transcription and promo pack for each episode."
      icon={
        <GradientIcon>
          <S.GiftIcon />
        </GradientIcon>
      }
      footer={
        <BaseUpsellModalFooter
          modalName="EddyAutomatedTranscriptions"
          cta="unlock this feature"
        />
      }
    />
  );
};

export default AutomatedTranscriptionsContents;
