import { Button, Replace } from '@sparemin/blockhead';
import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import notifyError from 'components/notification';
import { DEFAULT_UPSELL_PLAN } from 'config';
import useCheckoutPopup from 'hooks/useCheckoutPopup';
import { useAuth } from 'state/auth';
import { useEventTracking } from 'state/eventTracking';
import { subscriptionQueryKeys, useIsBasicOrFreeTier } from 'state/pricing';
import { useIsOptInAsset } from '../../state';
import { promoPackTabs } from '../constants';
import { useTopBar } from '../TopBar/TopBarContext';
import * as S from './styles';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { trackUnlockThisFeature } = useEventTracking();
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();
  const { assetType, onRegenerate, onCancel } = useTopBar();

  const queryClient = useQueryClient();
  const { userId } = useAuth();

  const isOptInAsset = useIsOptInAsset(assetType);

  const allowCancel = !isOptInAsset;

  const handleCheckout = useCallback((): void => {
    queryClient.invalidateQueries(subscriptionQueryKeys.mySubscription(userId));
  }, [queryClient, userId]);

  const { anchorProps } = useCheckoutPopup({
    onOpen() {
      trackUnlockThisFeature({
        modal: 'EddyPromoPackRegenerateAsset',
        assetType,
      });
    },
    onSuccess: handleCheckout,
    onError() {
      notifyError({
        heading: 'Error processing checkout',
      });

      handleCheckout();
    },
    targetPlan: DEFAULT_UPSELL_PLAN,
  });

  const submitButton = useMemo(() => {
    if (isOptInAsset) {
      return (
        <Button onPress={onRegenerate}>
          {`Generate ${promoPackTabs[assetType].title}`}
        </Button>
      );
    }

    return (
      <S.FooterButton startIcon={<Replace />} onPress={onRegenerate}>
        Regenerate
      </S.FooterButton>
    );
  }, [assetType, isOptInAsset, onRegenerate]);

  return (
    <S.Footer>
      {allowCancel && (
        <S.FooterButton variant="ghost" onPress={onCancel}>
          Cancel
        </S.FooterButton>
      )}

      {isBasicOrFreeTier ? (
        <S.FooterLinkButton fluid variant="special" {...anchorProps}>
          unlock this feature
        </S.FooterLinkButton>
      ) : (
        submitButton
      )}
    </S.Footer>
  );
};
export default Footer;
