import { useCallback } from 'react';
import { ClipSuggestion, ProjectTranscript } from 'api/services';
import { useTranscriptTimeRangeContext } from '../TranscriptTimeRangeContext';
import {
  canInsertChapterAtWordSelector,
  chaptersBySegmentsDictionarySelector,
  chaptersDictionarySelector,
  collapsedIntervalsSelector,
  indexedClipSuggestionsSelector,
  orderedClipSuggestionIdsSelector,
  segmentDictionarySelector,
  segmentIdsSelector,
  segmentInitialWordIndexSelector,
  transcriptIdSelector,
  wordDictionarySelector,
  wordIdListSelector,
  wordListSelector,
} from './selectors';
import { useEditorProjectTranscript } from './useEditorProject';
import useGetTranscript from './useGetTranscript';

export type UseEditorTranscriptSelector<TData = ProjectTranscript> = (
  transcript: ProjectTranscript,
) => TData;

export interface UseSegmentChapters {
  chapterId?: number;
  hasTitle?: boolean;
  words: number[];
}

/**
 * A wrapper around the base get-transcript query hook which injects the transcript
 * id from the project specified by the cuid in the page's query params.
 *
 * The transcript will only be fetched once the project request reports that
 * transcription is complete.  Until then, the data will be undefined
 */
export default function useEditorTranscript<TData = ProjectTranscript>(
  select?: UseEditorTranscriptSelector<TData>,
) {
  const { data: transcript } = useEditorProjectTranscript();
  const { transcriptTimeRange } = useTranscriptTimeRangeContext();
  const { startMillis, endMillis } = transcriptTimeRange ?? {};

  return useGetTranscript<TData>(
    transcript?.id as number,
    startMillis,
    endMillis,
    {
      select,
      enabled: transcript?.status === 'completed',
      // we don't really want to run into any issues where multiple requests are
      // fired off simultaneously because the response is big.  also, because the
      // response is big, we're aggressive about removing the data from the cache
      // when it's no longer needed
      staleTime: Infinity,
    },
  );
}

export const useTranscriptId = () => useEditorTranscript(transcriptIdSelector);

export const useSegmentIds = () => useEditorTranscript(segmentIdsSelector);

export const useCollapsedIntervals = () =>
  useEditorTranscript(collapsedIntervalsSelector);

export const useSegmentsById = () =>
  useEditorTranscript(segmentDictionarySelector);

export const useTranscriptSegment = (segmentId: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        const segmentsById = segmentDictionarySelector(d);
        return segmentsById[segmentId];
      },
      [segmentId],
    ),
  );

export const useWordsById = () => useEditorTranscript(wordDictionarySelector);

export const useWord = (wordId: number | undefined) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        if (wordId === undefined) {
          return undefined;
        }

        const wordsById = wordDictionarySelector(d);
        return wordsById[wordId];
      },
      [wordId],
    ),
  );

export const useSegmentWords = (segmentId: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        const segmentsById = segmentDictionarySelector(d);
        return segmentsById[segmentId]?.words.map((w) => w.id);
      },
      [segmentId],
    ),
  );

export const useSegmentSpeaker = (segmentId: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        const segmentsById = segmentDictionarySelector(d);
        return segmentsById[segmentId]?.speaker;
      },
      [segmentId],
    ),
  );

// TODO SPAR-20329 - shouldn't store multiple copies of the words in memory
// unnecessarily.  useWordList can be replaced by a combination of useWordIds
// and useWordsById
export const useWordList = () => useEditorTranscript(wordListSelector);

export const useWordIds = () => useEditorTranscript(wordIdListSelector);

export const useIndexedClipSuggestions = (
  suggestions: ClipSuggestion[] | undefined,
) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => indexedClipSuggestionsSelector(d, suggestions),
      [suggestions],
    ),
  );

export const useOrderedClipSuggestionIds = (
  suggestions: ClipSuggestion[] | undefined,
) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) =>
        orderedClipSuggestionIdsSelector(d, suggestions),
      [suggestions],
    ),
  );

export const useSegmentInitialWordIndex = (segmentId: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => segmentInitialWordIndexSelector(d, segmentId),
      [segmentId],
    ),
  );

export const useChapter = (chapterId: number | undefined) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        if (chapterId === undefined) {
          return undefined;
        }

        const chaptersById = chaptersDictionarySelector(d);
        return chaptersById[chapterId];
      },
      [chapterId],
    ),
  );

export const useChaptersById = () =>
  useEditorTranscript(chaptersDictionarySelector);

export const useChaptersBySegments = () =>
  useEditorTranscript(chaptersBySegmentsDictionarySelector);

/**
 * Segment chapters are organized as a list of chapters and each
 * element in the list contains the list of words for that chapter and
 * some chapter information composing the whole segment.
 *
 * It returns a list similar to:
 *
 * [
 *  {
 *    words: [1, 2, 3, 4, 5...]
 *  }
 *  {
 *    chapterId: 1,
 *    hasTitle: true,
 *    words: [6, 7, 8, 9, 10...]
 *  }
 *  {
 *    chapterId: 2,
 *    hasTitle: false,
 *    words: [11, 12, 13, 14, 15...]
 *  }
 *  {
 *    words: [16, 17, 18, 19, 20...]
 *  }
 * ]
 *
 * When `chapterId` and `hasTitle` are present it means that
 * the object belongs to a beginning of a new chapter. On the other
 * hand, when only the `words` object is present, it means that
 * that object belongs to a chapter fragment, which can be
 * at the beggining, middle or end of a segment.
 */
export const useSegmentChapters = (segmentId: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        const segmentsById = segmentDictionarySelector(d);
        const segment = segmentsById[segmentId];
        const words = segment?.words.map((word) => word.id) ?? [];
        const chapters = chaptersBySegmentsDictionarySelector(d)?.[segmentId];
        const transcriptWords = wordIdListSelector(d);
        const segmentInitialWordIndex = segmentInitialWordIndexSelector(
          d,
          segmentId,
        );
        const result: UseSegmentChapters[] = [];

        // If there's no chapters we can simply use the words ids.
        if (!chapters.length) {
          result.push({
            words,
          });

          return result;
        }

        chapters.forEach((chapter, index) => {
          const startWordIndex =
            transcriptWords.indexOf(chapter?.startWordId) -
            segmentInitialWordIndex;
          const nextStartWordIndex =
            transcriptWords.indexOf(chapters[index + 1]?.startWordId) -
            segmentInitialWordIndex;

          const isInitialChapter = index === 0;
          const isInitialWord = startWordIndex === 0;
          const fragmentEndIndex =
            nextStartWordIndex >= 0 ? nextStartWordIndex : words.length;
          const chapterInfo = {
            chapterId: chapter.id,
            hasTitle: !!chapter.title,
          };

          // If the chapter starts at the beginning of the segment.
          if (isInitialChapter && isInitialWord) {
            return result.push({
              ...chapterInfo,
              words: words.slice(0, fragmentEndIndex),
            });
          }

          // If the chapter is a continuation from a previous
          // segment at the beginning of a new segment.
          if (isInitialChapter && !isInitialWord) {
            result.push({
              words: words.slice(0, startWordIndex),
            });
          }

          // If the chapter doesn't starts at the beginning of a segment.
          return result.push({
            ...(startWordIndex !== -1 && chapterInfo),
            words: words.slice(startWordIndex, fragmentEndIndex),
          });
        });

        return result;
      },
      [segmentId],
    ),
  );

export const useCanInsertSegment = (wordId: number | undefined) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        if (wordId === undefined) {
          return false;
        }

        const wordsById = wordDictionarySelector(d);
        const segmentsById = segmentDictionarySelector(d);
        const word = wordsById[wordId];
        const segment = segmentsById[word.segmentId];

        // can't insert segment for a word that's the first words in the segment
        return segment.words[0].id !== wordId;
      },
      [wordId],
    ),
  );

export const useCanDeleteSegment = (segmentId?: number) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) => {
        if (!segmentId) {
          return false;
        }

        const ids = segmentIdsSelector(d);

        return ids[0] !== segmentId;
      },
      [segmentId],
    ),
  );

export const useCanInsertChapter = (wordId: number | undefined) =>
  useEditorTranscript(
    useCallback(
      (d: ProjectTranscript) =>
        !wordId ? false : canInsertChapterAtWordSelector(d, wordId),
      [wordId],
    ),
  );
