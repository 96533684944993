import { useMemo } from 'react';
import { FeatureDataContext } from './FeatureDataContext';
import { FeatureDataProviderProps } from './types';

function FeatureDataProvider<TData = undefined>({
  children,
  data,
}: FeatureDataProviderProps<TData>) {
  return (
    <FeatureDataContext.Provider value={useMemo(() => ({ data }), [data])}>
      {children}
    </FeatureDataContext.Provider>
  );
}

export default FeatureDataProvider;
