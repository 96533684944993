import { Video } from '@sparemin/blockhead';
import React, { useEffect } from 'react';

import { ProjectMediaType } from 'api';
import { ShareExportingBody } from 'components/ExportModalContents';
import Modal from 'components/Modal/Modal';
import {
  useExportAudioToHeadliner,
  useExportVideoToHeadliner,
  useProjectCuid,
  useProjectMediaType,
  useProjectName,
  useWordIds,
  useWordsById,
} from 'pages/TranscriptEditorPage/state';
import { useModalsActions } from 'state/modal';
import { AudiogramExportModalConfig } from 'state/modal/modals';

import { queryStatusResolver } from 'state/utils';
import { getSelectionStartEndWords } from '../utils';
import * as S from './styles';

interface AudiogramExportModalContentsProps {
  params?: AudiogramExportModalConfig['params'];
}

const AUDIO_PROJECT_MEDIA_TYPES: ProjectMediaType[] = ['audio', 'episode'];

const AudiogramExportModalContents: React.FunctionComponent<
  AudiogramExportModalContentsProps
> = (props) => {
  const { params } = props;
  const { words = [] } = params || {};

  const { data: projectCuid } = useProjectCuid();
  const { data: projectType } = useProjectMediaType();
  const { data: projectName } = useProjectName();
  const { data: wordsById = {} } = useWordsById();
  const { data: wordIds = [] } = useWordIds();

  const { startWord: transcriptStartWord, endWord: transcriptEndWord } =
    getSelectionStartEndWords(wordsById, [
      wordIds[0],
      wordIds[wordIds.length - 1],
    ]) || {};
  const { startWord: clipStartWord, endWord: clipEndWord } =
    getSelectionStartEndWords(wordsById, words) || {};

  const isClip = !!words.length;
  const startWord = isClip ? clipStartWord : transcriptStartWord;
  const endWord = isClip ? clipEndWord : transcriptEndWord;

  const { status: exportAudioStatus, exportAudioToHeadliner } =
    useExportAudioToHeadliner();
  const { status: exportVideoStatus, exportVideoToHeadliner } =
    useExportVideoToHeadliner({
      projectName,
      startMillis: startWord?.startMillis,
      endMillis: endWord?.endMillis,
    });
  const { pushModal } = useModalsActions();

  const status = queryStatusResolver([exportAudioStatus, exportVideoStatus]);

  useEffect(() => {
    if (status === 'idle' && projectCuid && startWord && endWord) {
      (projectType && AUDIO_PROJECT_MEDIA_TYPES.includes(projectType)
        ? exportAudioToHeadliner
        : exportVideoToHeadliner)({
        projectCuid,
        isClip,
        onSuccess: (url) => {
          pushModal({
            name: 'CreateAudiogram',
            params: {
              url,
              isClip,
            },
          });

          const win = window.open(url, '_blank');

          win?.focus();
        },
        selection: {
          startWordId: startWord.id,
          endWordId: endWord.id,
        },
      });
    }
  }, [
    endWord,
    exportAudioToHeadliner,
    exportVideoToHeadliner,
    isClip,
    projectCuid,
    projectType,
    pushModal,
    startWord,
    status,
  ]);

  return (
    <S.ModalContents
      icon={
        <Modal.Icon>
          <Video width="60px" />
        </Modal.Icon>
      }
      status="loading"
      title={`Preparing ${isClip ? 'clip' : 'audiogram'}`}
      subtitle={
        isClip
          ? null
          : 'Important: Please keep this dialog open to ensure your ongoing export continues. If you close this dialog, your export will be halted.'
      }
    >
      <ShareExportingBody />
    </S.ModalContents>
  );
};

export default AudiogramExportModalContents;
