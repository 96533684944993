import { ButtonProps, Tooltip } from '@sparemin/blockhead';
import React from 'react';
import { useWordsById } from 'pages/TranscriptEditorPage/state';
import { useWordsContextMenuConfig } from 'pages/TranscriptEditorPage/state/contextMenu';
import { getSelectionStartEndMillis } from 'pages/TranscriptEditorPage/utils';
import * as S from './styles';

export type CreateClipButtonProps = Pick<
  ButtonProps,
  'aria-label' | 'onPress'
> & {
  tooltip?: string;
};

const CreateClipButton: React.FC<CreateClipButtonProps> = ({
  'aria-label': ariaLabel = 'create clip',
  onPress,
  tooltip = 'Create clip',
}) => {
  const config = useWordsContextMenuConfig();
  const words = config?.words;

  const { data: wordsById } = useWordsById();

  const { startMillis, endMillis } =
    getSelectionStartEndMillis(wordsById, words) ?? {};

  return (
    <Tooltip contents={tooltip}>
      <S.DurationButton
        aria-label={ariaLabel}
        onPress={onPress}
        interval={{ startMillis, endMillis }}
      />
    </Tooltip>
  );
};
export default CreateClipButton;
