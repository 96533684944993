import { Spacer, UnloadingAudio } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import FadingFooter from 'components/FadingFooter';
import { AudioPlayer } from 'utils/audio';
import { AudioPlayerProvider } from '../AudioPlayerContext';
import {
  TRANSCRIPT_CLASSNAME,
  TRANSCRIPT_EDITOR_ARTICLE_CLASSNAME,
  TRANSCRIPT_EDITOR_BODY_CLASSNAME,
  TRANSCRIPT_EDITOR_CLASSNAME,
} from '../constants';
import EditorOrchestrator from '../EditorOrchestrator';
import { ControlledFeature } from '../features';
import { useFeatureConfig } from '../features/FeatureContext';
import { useProjectAssetUrl, useProjectMediaType } from '../state';
import { EditTranscriptProvider } from '../state/commands';
import {
  useHasPromoPacks,
  usePromoPackIsComplete,
} from '../state/useGetPromoPacks';
import Modals from './Modals';
import * as S from './styles';
import useTrackTranscriptPageLoaded from './useTrackTranscriptPageLoaded';

export interface TranscriptEditorProps {}

const TranscriptEditor: React.FC<TranscriptEditorProps> = () => {
  const [player, setPlayer] = useState<AudioPlayer>();
  const { data: assetUrl } = useProjectAssetUrl();
  const { data: projectMediaType } = useProjectMediaType();
  const { data: hasPromoPacks } = useHasPromoPacks();
  const { data: promoPackIsComplete } = usePromoPackIsComplete();
  const { isDisabled: isNavbarFeatureDisabled } = useFeatureConfig('navbar');

  const { audioPlayerFactory } = useFeatureConfig('player');

  useTrackTranscriptPageLoaded();

  const { ref, inView } = useInView({
    threshold: 1,
  });

  const handleAudioRef = useCallback(
    (el: HTMLAudioElement) => {
      if (el) {
        setPlayer(audioPlayerFactory(el));
      } else {
        setPlayer(undefined);
      }
    },
    [audioPlayerFactory],
  );

  const needsFadingHeader = !isNavbarFeatureDisabled || !inView;

  // TODO: figure out what to do if assetUrl is not defined
  return (
    <S.Container className={TRANSCRIPT_EDITOR_CLASSNAME} ref={ref}>
      {player && (
        <AudioPlayerProvider player={player}>
          <EditTranscriptProvider>
            <ControlledFeature feature="custom" />
            <ControlledFeature feature="explorerBar" />
            <EditorOrchestrator />
            {needsFadingHeader && <S.FadingHeader />}
            <Spacer
              className={TRANSCRIPT_EDITOR_BODY_CLASSNAME}
              orientation="horizontal"
            >
              <S.TranscriptContainer
                className={TRANSCRIPT_EDITOR_ARTICLE_CLASSNAME}
              >
                <S.Transcript className={TRANSCRIPT_CLASSNAME} />
              </S.TranscriptContainer>

              {projectMediaType === 'video' && (
                <ControlledFeature feature="videoPreview">
                  {(preview) =>
                    preview && <S.VideoContainer>{preview}</S.VideoContainer>
                  }
                </ControlledFeature>
              )}
            </Spacer>

            {hasPromoPacks && promoPackIsComplete && (
              <ControlledFeature feature="promoPack.popper" />
            )}

            <ControlledFeature feature="player.playbar" />
            <Modals />
            {!inView && <FadingFooter />}
          </EditTranscriptProvider>
        </AudioPlayerProvider>
      )}
      {assetUrl && <UnloadingAudio src={assetUrl} ref={handleAudioRef} />}
    </S.Container>
  );
};

export default TranscriptEditor;
