import { useCallback } from 'react';
import { TranscriptFormat } from 'api/services/transcript';
import { useExpressExportFormatsOverride } from 'state/displayPref';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { useIsIntegrator } from 'state/user';
import {
  usePostExportRedirectUrl,
  useProjectCuid,
  useProjectMediaType,
  useProjectName,
  useProjectUrlTraceId,
} from '../../state';

/**
 * A few different conditions are taken into account when deciding which export
 * modal a user should see:
 *  - If the user is an integrator, the exports might be getting sent directly to
 *    to the integrator and made available to the user on thier site, which means
 *    that we shouldn't necessarily download the exported assets for the user
 *  - The user might have a configuration specifying a set of assets that should be
 *    created on each export, meaning there's arguably no reason for them to see the
 *    export modal that allows theem to choose what they want to export
 *
 *
 * For now, we support 2 different flows:
 *  1. Most users will see the standard use case - they click export and a modal opens
 *     with all of the export options.  The user chooses one, sees a loading state
 *     while it's being created, and then the asset gets downloaded automatically upon
 *     completion
 *  2. Integrators who have both a "post export redirect url" and "express export"
 *     set up will not see the modal that allows them to choose assets.  We automatically
 *     generate all assets as described by their "expressExport" config and when all
 *     assets have been exported, we redirect them back to their "home" site
 *
 */
export default function useOpenExportModal() {
  const { trackExportButtonClick } = useEventTracking();

  const { data: projectCuid = '', isFetched: isProjectDataFetched } =
    useProjectCuid();
  const { data: projectName = '' } = useProjectName();

  const { data: projectMediaType } = useProjectMediaType();

  const { data: isIntegrator, isFetched: isIntegratorFetched } =
    useIsIntegrator();

  const { data: expressExportFormats, isFetched: isExpressExportFetched } =
    useExpressExportFormatsOverride();

  const { data: postExportRedirectUrl, isFetched: isRedirectUrlFetched } =
    usePostExportRedirectUrl();

  const { data: traceId, isFetched: isTraceIdFetched } = useProjectUrlTraceId();

  const { pushModal } = useModalsActions();

  const openExportModal = useCallback(() => {
    trackExportButtonClick();

    if (
      isIntegrator &&
      expressExportFormats &&
      expressExportFormats.length > 0 &&
      postExportRedirectUrl
    ) {
      pushModal({
        name: 'IntegratorExport',
        params: {
          projectCuid,
          projectName,
          exportAudio: expressExportFormats.includes('audio'),
          redirectUrl: postExportRedirectUrl,
          transcriptFormats: expressExportFormats.filter(
            (f) => f !== 'audio',
          ) as TranscriptFormat[],
        },
      });
    } else {
      pushModal({
        name: 'ShareProject',
        params: {
          projectCuid,
          projectName,
          projectMediaType,
        },
      });
    }
  }, [
    expressExportFormats,
    isIntegrator,
    postExportRedirectUrl,
    projectCuid,
    projectMediaType,
    projectName,
    pushModal,
    trackExportButtonClick,
  ]);

  const canOpenExportModal = [
    isProjectDataFetched,
    isIntegratorFetched,
    isExpressExportFetched,
    isTraceIdFetched && !traceId ? true : isRedirectUrlFetched,
  ].every((v) => v === true);

  return {
    canOpenExportModal,
    openExportModal,
  };
}
