import { MediaFileType } from 'api';

export function getMediaTypeFromFileType(
  mimeType: string | undefined,
): MediaFileType | undefined {
  if (!mimeType) {
    return;
  }

  if (mimeType.includes('video')) {
    return 'video';
  }

  if (mimeType.includes('audio')) {
    return 'audio';
  }

  return undefined;
}
