import {
  FavoritePodcastsResponse,
  Podcast,
  TranscriptSubscriptionsResult,
} from 'api';

export const myDefaultPodcastSelector = (
  d: FavoritePodcastsResponse,
): Podcast | undefined => d.podcasts[0];

export const hasDefaulPodcastSelector = (d: FavoritePodcastsResponse) =>
  myDefaultPodcastSelector(d) !== undefined;

export const transcriptSubscriptionsIdsSelector = (
  d: TranscriptSubscriptionsResult,
): number[] => d.map((s) => s.id);

export const transcriptSubscriptionSelector = (
  d: TranscriptSubscriptionsResult,
  subscriptionId: number,
) => d?.find((s) => s.id === subscriptionId);

export const hasTranscriptSubscriptionsSelector = (
  d: TranscriptSubscriptionsResult,
): boolean => !!d.length;

export const myTranscriptSubscriptionsPodcastIdsSelector = (
  d: TranscriptSubscriptionsResult,
): string[] => d.map((subscription) => subscription.podcast.podcastId);
