import EpisodeArtAdjustmentsBar from './CustomEpisodeArtAdjustmentsBar';
import CustomFeedbackAdjustmentsBar from './CustomFeedbackAdjustmentsBar';
import EpisodeArtTabPanel from './EpisodeArtTabPanel';
import StructuredAdjustmentsBar from './StructuredAdjustmentsBar';
import TextTabPanel from './TextTabPanel';
import { PromoPackTabs, PromoPackTabType } from './types';
import WelcomeTabPanel from './WelcomeTabPanel';

export const promoPackTabs: PromoPackTabs = {
  welcome: {
    title: 'Welcome',
    icon: '👋',
    content: <WelcomeTabPanel />,
  },
  showNotes: {
    title: 'Show notes',
    icon: '💬',
    topBarContent: (
      <StructuredAdjustmentsBar title="Adjust Show Notes" showIncludeChapters />
    ),
    content: <TextTabPanel assetType="showNotes" />,
  },
  episodeArt: {
    title: 'Episode art',
    icon: '🎨',
    topBarContent: <EpisodeArtAdjustmentsBar />,
    content: <EpisodeArtTabPanel />,
  },
  keywords: {
    title: 'Keywords',
    icon: '🔑',
    topBarContent: <CustomFeedbackAdjustmentsBar title="Adjust Keywords" />,
    content: <TextTabPanel assetType="keywords" />,
  },
  socialCaptions: {
    title: 'Social copy',
    icon: '📣',
    topBarContent: <StructuredAdjustmentsBar title="Adjust Social copy" />,
    content: <TextTabPanel assetType="socialCaptions" />,
  },
  quotes: {
    title: 'Quotes',
    icon: '🗣️',
    topBarContent: <CustomFeedbackAdjustmentsBar title="Adjust Quotes" />,
    content: <TextTabPanel assetType="quotes" />,
  },
  references: {
    title: 'References',
    icon: '✍️',
    topBarContent: <CustomFeedbackAdjustmentsBar title="Adjust References" />,
    content: <TextTabPanel assetType="references" />,
  },
  newsletter: {
    title: 'Newsletter',
    icon: '✉️',
    topBarContent: <StructuredAdjustmentsBar title="Adjust Newsletter" />,
    content: <TextTabPanel assetType="newsletter" />,
  },
  blogPost: {
    title: 'Blog Post',
    icon: '📝',
    topBarContent: <StructuredAdjustmentsBar title="Adjust Blog Post" />,
    content: <TextTabPanel assetType="blogPost" />,
  },
};

export const promoPackModalTabsOrder: PromoPackTabType[] = [
  'welcome',
  'showNotes',
  'episodeArt',
  'keywords',
  'socialCaptions',
  'quotes',
  'references',
  'newsletter',
  'blogPost',
];

export const optInAssets: PromoPackTabType[] = ['episodeArt'];
