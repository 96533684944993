import React from 'react';
import { FeatureContext } from './FeatureContext';
import { FeatureProviderProps } from './types';

const DEFAULT_CONFIG = {};

const FeatureProvider: React.FC<FeatureProviderProps> = ({
  config = DEFAULT_CONFIG,
  children,
}) => (
  <FeatureContext.Provider value={config}>{children}</FeatureContext.Provider>
);

export default FeatureProvider;
