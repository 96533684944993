import { useContext } from 'react';
import EmbedPageQueryParamContext from './EmbedPageQueryParamContext';

export default function useEmbedPageQueryParams() {
  const context = useContext(EmbedPageQueryParamContext);

  if (context === undefined) {
    throw new Error(
      'useEmbedPageQueryParams must be used within EmbedPageQueryParamProvider',
    );
  }

  return context;
}
