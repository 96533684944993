import React from 'react';
import useDownloadClip from 'pages/TranscriptEditorPage/hooks/useDownloadClip';
import { FeatureProvider } from '../../features';
import TranscriptEditorPage from '../../TranscriptEditorPage';
import featureConfig from './featureConfig';
import { NativeTranscriptEditorPageProvider } from './NativeTranscriptEditorPageContext';

export interface NativeTranscriptEditorPageProps {}

const NativeTranscriptEditorPage: React.FC<
  NativeTranscriptEditorPageProps
> = () => {
  const { downloadClip, status: downloadClipStatus } = useDownloadClip();

  return (
    <NativeTranscriptEditorPageProvider
      {...{ downloadClip, downloadClipStatus }}
    >
      <FeatureProvider config={featureConfig}>
        <TranscriptEditorPage />
      </FeatureProvider>
    </NativeTranscriptEditorPageProvider>
  );
};

export default NativeTranscriptEditorPage;
