import { HelpMenu, LogoItem } from 'components/AppHeader';
import { MediaElementAudioPlayer } from 'utils/audio';
import AddIntroOutroModal from '../../AddIntroOutroModal';
import AdjustSelectionModal from '../../AdjustSelectionModal';
import AudiogramExportModal from '../../AudiogramExportModal';
import ChapterInfoModal from '../../ChapterInfoModal';
import { TIMEUPDATE_POLLING_INTERVAL_MILLIS } from '../../constants';
import DeleteChapterModal from '../../DeleteChapterModal/DeleteChapterModal';
import EditableProjectTitle from '../../EditableProjectTitle';
import AdjustSelectionActionButton from '../../EditorContextMenu/WordContextMenu/ActionMenuContents/AdjustSelectionActionButton';
import ExportTranscriptModal from '../../ExportTranscriptModal';
import ExportURLsModal from '../../ExportURLsModal';
import {
  AudioPlayerFactory,
  TranscriptEditorPageFeatureConfig,
} from '../../features/types';
import Header from '../../Header';
import IntegratorExportModal from '../../IntegratorExportModal/IntegratorExportModal';
import IntroOutroMediaUploadModal from '../../IntroOutroMediaUploadModal';
import Playbar from '../../Playbar';
import PromoPackModal from '../../PromoPackModal';
import PromoPopper from '../../PromoPopper';
import ShareAudioModal from '../../ShareAudioModal';
import ShareProjectModal from '../../ShareProjectModal';
import { IntroOutro } from '../../Transcript';
import { TranscriptExplorerBar } from '../../TranscriptEditor';
import { TranscriptTimeRangeProvider } from '../../TranscriptTimeRangeContext';
import VideoPreview from '../../VideoPreview';
import AudiogramContextMenuButton from './AudiogramContextMenuButton';
import ChapterTitle from './ChapterTitle';
import DownloadClipContextMenuButton from './DownloadClipContextMenuButton';
import ExportProjectButton from './ExportProjectButton';
import InsertChapterButton from './InsertChapterButton';
import SuggestionPopover from './SuggestionPopover';

const audioPlayerFactory: AudioPlayerFactory = (el) =>
  new MediaElementAudioPlayer(el, TIMEUPDATE_POLLING_INTERVAL_MILLIS);

const featureConfig: TranscriptEditorPageFeatureConfig = {
  chapters: {
    addChapter: {
      contextMenuButton: {
        element: <InsertChapterButton />,
      },
    },
    modal: {
      element: (
        <>
          <ChapterInfoModal />
          <DeleteChapterModal />
        </>
      ),
    },
    title: {
      element: <ChapterTitle />,
    },
  },
  edgeAssets: {
    introUploader: {
      element: <IntroOutro variant="intro" />,
    },
    modal: {
      element: (
        <>
          <AddIntroOutroModal />
          <IntroOutroMediaUploadModal />
        </>
      ),
    },
    outroUploader: {
      element: <IntroOutro variant="outro" />,
    },
  },
  editing: {
    deletionAdjustment: {
      contextMenuButton: {
        element: <AdjustSelectionActionButton />,
      },
      modal: {
        element: <AdjustSelectionModal />,
      },
    },
  },
  explorerBar: {
    element: <TranscriptExplorerBar />,
  },
  export: {
    contextMenu: {
      audiogramButton: {
        element: <AudiogramContextMenuButton />,
      },
      downloadButton: {
        element: <DownloadClipContextMenuButton />,
      },
    },
    exportButton: {
      element: <ExportProjectButton />,
    },
    modal: {
      element: (
        <>
          <AudiogramExportModal />
          <ExportTranscriptModal />
          <ExportURLsModal />
          <IntegratorExportModal />
          <ShareProjectModal />
          <ShareAudioModal />
        </>
      ),
    },
  },
  navbar: {
    element: <Header />,
    logo: {
      element: <LogoItem variant="full" />,
    },
  },
  player: {
    audioPlayerFactory,
    playbar: {
      element: <Playbar />,
    },
  },
  projectTitle: {
    element: <EditableProjectTitle />,
  },
  promoPack: {
    modal: {
      element: <PromoPackModal />,
    },
    popper: {
      element: <PromoPopper />,
    },
  },
  suggestedClips: {
    popper: {
      element: <SuggestionPopover />,
    },
  },
  support: {
    supportButton: {
      element: <HelpMenu />,
    },
  },
  videoPreview: {
    element: <VideoPreview />,
  },
  transcript: {
    timecodes: {
      provider: {
        element: <TranscriptTimeRangeProvider />,
      },
    },
  },
};

export default featureConfig;
