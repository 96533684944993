import React, { useMemo } from 'react';
import NativeTranscriptEditorPageContext from './NativeTranscriptEditorPageContext';
import { NativeTranscriptEditorPageProviderProps } from './types';

const NativeTranscriptEditorPageProvider: React.FC<
  NativeTranscriptEditorPageProviderProps
> = ({ children, downloadClip, downloadClipStatus }) => (
  <NativeTranscriptEditorPageContext.Provider
    value={useMemo(
      () => ({ downloadClip, downloadClipStatus }),
      [downloadClip, downloadClipStatus],
    )}
  >
    {children}
  </NativeTranscriptEditorPageContext.Provider>
);

export default NativeTranscriptEditorPageProvider;
