import React from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import EmbedPageQueryParamProvider from './EmbedPageQueryParamProvider';
import { getContext, getMargin, getTranscriptTimeRange } from './utils';

export interface EmbedPageQueryParamProviderRouteProps {}

const EmbedPageQueryParamProviderRoute: React.FC<
  EmbedPageQueryParamProviderRouteProps
> = () => {
  const [searchParams] = useSearchParams();

  return (
    <EmbedPageQueryParamProvider
      context={getContext(searchParams)}
      margin={getMargin(searchParams)}
      transcriptTimeRange={getTranscriptTimeRange(searchParams)}
    >
      <Outlet />
    </EmbedPageQueryParamProvider>
  );
};

export default EmbedPageQueryParamProviderRoute;
