import { transparentize } from 'color2k';
import styled from 'styled-components';
import { transparent } from 'utils/colors';

export const FadingHeader = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(
      ${theme.backgroundColorMain},
      ${theme.backgroundColorMain} 35%,
      ${transparentize(theme.backgroundColorMain, 0.5)} 70%,
      ${transparent(theme.backgroundColorMain)}
    )`};
  height: ${({ theme }) => theme.headerHeightDesktop}px;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
`;

export default FadingHeader;
