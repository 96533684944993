import { produce } from 'immer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { LazyThemedPage } from 'components/App/Router/lazyRoutes';
import { darkTheme } from 'theme';
import {
  EmbedPageQueryParamProviderContextType,
  useEmbedPageQueryParams,
} from './EmbedPageQueryParamProviderRoute';

export interface LazyEmbedPageProps {}

function getTheme({ context, margin }: EmbedPageQueryParamProviderContextType) {
  if (context === 'default') {
    return darkTheme;
  }

  return produce(darkTheme, (d) => {
    d.backgroundColorMain = d.palette.l2;
    d.headerHeightDesktop = margin?.top ?? 25;
    d.footerFadeHeight = margin?.bottom ?? 25;
  });
}

const LazyEmbedPage: React.FC<LazyEmbedPageProps> = () => {
  const params = useEmbedPageQueryParams();

  return (
    <LazyThemedPage theme={getTheme(params)}>
      <Outlet />
    </LazyThemedPage>
  );
};

export default LazyEmbedPage;
