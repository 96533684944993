import api, { UserSubscription, ProductTier } from '@sparemin/api-sdk';
import { useCallback } from 'react';
import { Query, useQuery } from 'react-query';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { subscriptionQueryKeys } from './queryKeys';
import {
  hasTranscriptAutomationFeatureSelector,
  isBasicOrFreeTierSelector,
  isFreeTierSelector,
  isMonthlyTranscriptionExceededSelector,
  planDurationLimitSelector,
  planMaxUploadMbSelector,
  planMaxUploadValuesSelector,
  planMonthlyTranscriptionDurationHoursSelector,
  planMonthlyTranscriptionDurationMillisSelector,
  planMonthlyTranscriptionRemainingDurationMillisSelector,
  planNameSelector,
} from './selectors';

type QueryKey = ReturnType<typeof subscriptionQueryKeys.mySubscription>;

export interface UsePricingOptions<TData = UserSubscription>
  extends QueryOptions<UserSubscription, TData, QueryKey> {
  pollingInterval?: number;
  waitForTier?: ProductTier;
}

function usePlanSubscription<TData = UserSubscription>(
  opts?: UsePricingOptions<TData>,
) {
  const {
    enabled = true,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    waitForTier,
  } = opts ?? {};
  const { userId } = useAuth();

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<UserSubscription, unknown, UserSubscription, QueryKey>,
    ) => {
      const tier = query.state.data?.subscribedProduct.tier;
      return tier !== waitForTier ? pollingInterval : false;
    },
    [pollingInterval, waitForTier],
  );

  return useQuery(
    subscriptionQueryKeys.mySubscription(userId),
    useCallback(() => api.getMySubscription(), []),
    {
      ...opts,
      enabled: !!userId && enabled,
      refetchInterval: waitForTier !== undefined ? refetchInterval : undefined,
    },
  );
}

export const useIsFreeTier = () =>
  usePlanSubscription({ select: isFreeTierSelector });

export const useIsBasicOrFreeTier = () =>
  usePlanSubscription({ select: isBasicOrFreeTierSelector });

export const usePlanName = () =>
  usePlanSubscription({ select: planNameSelector });

export const usePollForNewTier = (tier?: ProductTier) =>
  usePlanSubscription({
    enabled: !!tier,
    waitForTier: tier,
  });

export const usePlanMaxUploadMb = () =>
  usePlanSubscription({ select: planMaxUploadMbSelector });

export const usePlanDurationLimitHours = () =>
  usePlanSubscription({ select: planDurationLimitSelector });

export const usePlanMonthlyDurationLimitMillis = () =>
  usePlanSubscription({
    select: planMonthlyTranscriptionDurationMillisSelector,
  });

export const usePlanMonthlyDurationLimitHours = () =>
  usePlanSubscription({
    select: planMonthlyTranscriptionDurationHoursSelector,
  });

export const usePlanUploadMaxValues = () =>
  usePlanSubscription({ select: planMaxUploadValuesSelector });

export const useIsMonthlyTranscriptionExceeded = () =>
  usePlanSubscription({ select: isMonthlyTranscriptionExceededSelector });

export const useMonthlyTranscriptionRemainingDurationMillis = () =>
  usePlanSubscription({
    select: planMonthlyTranscriptionRemainingDurationMillisSelector,
  });

export const useHasTranscriptAutomationFeature = () =>
  usePlanSubscription({
    select: hasTranscriptAutomationFeatureSelector,
  });
