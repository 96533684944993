import React from 'react';
import { ControlledFeature } from '../features';
import * as S from './styles';

export interface TitleProps {}

const Title: React.FC<TitleProps> = () => {
  return (
    <ControlledFeature feature="projectTitle">
      {(title) =>
        title && <S.ProjectTitleContainer>{title}</S.ProjectTitleContainer>
      }
    </ControlledFeature>
  );
};

export default Title;
