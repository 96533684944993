import { request } from 'api';
import {
  PODCAST_SERVICE_V1_FAVORITES_URL,
  PODCAST_SERVICE_V1_TRANSCRIPT_SUBSCRIPTION_URL,
} from './constants';
import {
  CreateTranscriptSubscriptionArgs,
  DeleteTranscriptSubscriptionArgs,
  FavoritePodcastsResponse,
  TranscriptSubscriptionsResponse,
  TranscriptSubscriptionsResult,
} from './types';

export async function getFavoritePodcasts() {
  const { data } = await request.get<FavoritePodcastsResponse>(
    PODCAST_SERVICE_V1_FAVORITES_URL,
  );

  return data;
}

export async function getTranscriptSubscriptions(): Promise<TranscriptSubscriptionsResult> {
  const { data } = await request.get<TranscriptSubscriptionsResponse>(
    PODCAST_SERVICE_V1_TRANSCRIPT_SUBSCRIPTION_URL,
  );

  return data.data;
}

export async function createTranscriptSubscription(
  args: CreateTranscriptSubscriptionArgs,
): Promise<void> {
  await request.post(PODCAST_SERVICE_V1_TRANSCRIPT_SUBSCRIPTION_URL, {
    ...args,
  });
}

export async function deleteTranscriptSubscription({
  transcriptSubscriptionId,
}: DeleteTranscriptSubscriptionArgs): Promise<void> {
  await request.delete(
    `${PODCAST_SERVICE_V1_TRANSCRIPT_SUBSCRIPTION_URL}/${transcriptSubscriptionId}`,
  );
}
