import React, { useMemo } from 'react';
import HeaderContext from './HeaderContext';
import { HeaderContextType } from './types';

export type HeaderProviderProps = React.PropsWithChildren<HeaderContextType>;

const HeaderProvider: React.FC<HeaderProviderProps> = ({
  canExport,
  children,
}) => (
  <HeaderContext.Provider value={useMemo(() => ({ canExport }), [canExport])}>
    {children}
  </HeaderContext.Provider>
);

export default HeaderProvider;
