import { Heading } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import UploadLimitsMessage from 'components/UploadLimitsMessage';
import { useModalsActions } from 'state/modal';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface UploadLimitsErrorContentsProps extends BaseUpsellModalProps {
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
}

const UploadLimitsErrorContents: React.FC<UploadLimitsErrorContentsProps> = ({
  onSuccess,
  title = 'This file is too big for your tier',
  subtitle,
}) => {
  const { hideModal } = useModalsActions();

  const handleOnSuccess = useCallback((): void => {
    hideModal();
    onSuccess?.();
  }, [hideModal, onSuccess]);

  return (
    <BaseUpsellModal
      title={title}
      subtitle={
        subtitle ?? (
          <Heading level={2}>
            <UploadLimitsMessage isInline isUpsell />
          </Heading>
        )
      }
      icon={<S.AlertIcon />}
      footer={
        <BaseUpsellModalFooter
          modalName="EddyUploadLimitError"
          onSuccess={handleOnSuccess}
        />
      }
    />
  );
};

export default UploadLimitsErrorContents;
