import { useCallback } from 'react';
import { ActionButton } from 'pages/TranscriptEditorPage/EditorContextMenu';
import { useCanInsertChapter } from 'pages/TranscriptEditorPage/state';
import { useWordsContextMenuConfig } from 'pages/TranscriptEditorPage/state/contextMenu';
import { useModalsActions } from 'state/modal';

export interface InsertChapterButtonProps {}

const InsertChapterButton: React.FC<InsertChapterButtonProps> = () => {
  const config = useWordsContextMenuConfig();

  const firstSelectedWordId = config?.words[0];

  const { data: canInsertChapter } = useCanInsertChapter(firstSelectedWordId);
  const { pushModal } = useModalsActions();

  const handleInsertChapter = useCallback(() => {
    pushModal({
      name: 'ChapterInfo',
      params: {
        newChapterStartWordId: firstSelectedWordId,
      },
    });
  }, [firstSelectedWordId, pushModal]);

  return (
    <ActionButton
      isDisabled={!canInsertChapter}
      onPress={handleInsertChapter}
      tooltip={!canInsertChapter ? 'A chapter already exists' : undefined}
    >
      insert chapter
    </ActionButton>
  );
};

export default InsertChapterButton;
