import { darken } from 'color2k';
import { produce } from 'immer';
import {
  DisplayPreferences,
  EddyFeaturesConfig,
  EddyFeaturesConfigKeys,
  EddyFeaturesKeyList,
} from 'api/services/headlinerUser';
import theme from 'theme';

export const logoUrlSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.logo?.default;

export const faviconSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.favicon?.default;

export const titleSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.tabTitle?.default;

export const primaryColorSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.buttonColor?.default;

export const helpButtonSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.helpButton?.enabled?.default ?? true;

export const themeOverrideSelector = (d: DisplayPreferences) => {
  const primaryColor = primaryColorSelector(d);

  return produce(theme, (draft) => {
    if (primaryColor) {
      draft.palette.actionPrimary.main = primaryColor;
      draft.palette.actionPrimary.dark = darken(primaryColor, 0.1);
    }
  });
};

export const exportFormatsOverrideSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.export?.assets?.overrideOptions;

export const expressExportFormatsOverrideSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.expressExport?.assets?.overrideOptions;

export const logoTargetOverrideSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.navigation?.target;

export const isClipSuggestionsEnabledSelector = (d: DisplayPreferences) =>
  d.displayAdjustment.eddy.features?.clipSuggestions?.enabled?.default;

export const eddyFeaturePrefOverrideSelector = (
  d: DisplayPreferences,
): Record<EddyFeaturesConfigKeys, boolean> | undefined => {
  const eddyFeaturesDisplayPrefs = d.displayAdjustment.eddy.features;

  if (!eddyFeaturesDisplayPrefs) {
    return undefined;
  }

  return EddyFeaturesKeyList.reduce((acc, key) => {
    const castedKey = key as keyof EddyFeaturesConfig;
    acc[castedKey] =
      eddyFeaturesDisplayPrefs?.[castedKey]?.enabled?.default ?? true;
    return acc;
  }, {} as Record<EddyFeaturesConfigKeys, boolean>);
};
