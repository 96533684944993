import { ControlledFeature } from 'pages/TranscriptEditorPage/features';
import { ContextMenuAction } from '../../../state/contextMenu';
import DeleteActionButton from './DeleteActionButton';
import EditTextActionButton from './EditTextActionButton';
import InsertElementActionButton from './InsertElementActionButton/InsertElementActionButton';
import * as S from './styles';
import UndeleteActionButton from './UndeleteActionButton';

export interface ActionMenuContentsProps {
  actions: ContextMenuAction[];
  onEditPress: () => void;
}

const ActionMenuContents: React.FC<ActionMenuContentsProps> = ({
  actions,
  onEditPress,
}) => {
  return (
    <S.Container>
      {actions.includes('adjust-selection') && (
        <ControlledFeature feature="editing.deletionAdjustment.contextMenuButton" />
      )}
      {actions.includes('delete-word') && <DeleteActionButton />}
      {actions.includes('undelete-word') && <UndeleteActionButton />}
      <EditTextActionButton onPress={onEditPress} />
      <InsertElementActionButton />

      {actions.includes('download') && (
        <ControlledFeature feature="export.contextMenu.downloadButton" />
      )}
      {actions.includes('headliner') && (
        <ControlledFeature feature="export.contextMenu.audiogramButton" />
      )}
    </S.Container>
  );
};

export default ActionMenuContents;
