import { ResetPasswordCard } from '@sparemin/auth';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import ThemedRoute from 'components/ThemedRoute';
import UnauthenticatedRoute from 'components/UnauthenticatedRoute';
import { LoginCard, RegistrationCard } from 'pages/AuthenticationPage';
import ErrorPage from 'pages/ErrorPage';
import {
  EmbedTranscriptEditorPage,
  EmbedPageQueryParamProviderRoute,
  LazyEmbedPage,
} from 'pages/TranscriptEditorPage';
import paths from 'paths';
import AppLayout from './AppLayout';
import {
  AuthenticationPage,
  CreateProjectPage,
  NativeTranscriptEditorPage,
  ProjectsPage,
  QueryStringAuthRoute,
  TranscriptOwnerRoute,
} from './lazyRoutes';
import ResponsiveLayoutSwitcher from './ResponsiveLayoutSwitcher';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />} errorElement={<ErrorPage />}>
      <Route element={<ResponsiveLayoutSwitcher />}>
        <Route path="*" element={<Navigate to={paths.dashboard.value} />} />
        <Route element={<QueryStringAuthRoute />}>
          {/*
           *  CreateProjectPage does not necessarily have to be wrapped in
           *  AuthenticatedRoute, however it acts as a failsafe and also makes it
           *  clear that the route is not designed to be accessed anonymously
           */}
          <Route element={<AuthenticatedRoute />}>
            <Route
              path={paths.projectCreate.value}
              element={<CreateProjectPage />}
            />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ThemedRoute />}>
            <Route path={paths.dashboard.value} element={<ProjectsPage />} />
            <Route element={<TranscriptOwnerRoute />}>
              <Route
                path={paths.editor.value}
                element={<NativeTranscriptEditorPage />}
              />
            </Route>
          </Route>
        </Route>

        {/*
         * embed pages have different backgrounds.  query params are used to decide
         * which background color to use.  in order to maintain a consistent background
         * color for the duration of the page load, read the query params early and
         * expose via context.  the first time a theme provider renders, it should
         * have enough information to decide which background color to use
         */}
        <Route element={<EmbedPageQueryParamProviderRoute />}>
          {/*
           * use query params to render theme. suspense is here, inside of the
           * theme so that the theme is also applied to the loading screen
           */}
          <Route element={<LazyEmbedPage />}>
            <Route element={<QueryStringAuthRoute />}>
              <Route element={<AuthenticatedRoute />}>
                {/*
                 * this route isn't asynchronously loaded. it's a small route that
                 * reads query params from the provider above and decides which
                 * variant of the embed editor to render.  those editors are loaded
                 * asynchronously.
                 */}
                <Route
                  path={paths.embed.value}
                  element={<EmbedTranscriptEditorPage />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<UnauthenticatedRoute />}>
          <Route element={<AuthenticationPage />}>
            <Route path={paths.login.value} element={<LoginCard />} />
            <Route
              path={paths.registration.value}
              element={<RegistrationCard />}
            />
            <Route
              path={paths.resetPassword.value}
              element={<ResetPasswordCard />}
            />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

export default router;
