import { Tooltip } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useModalsActions } from 'state/modal';
import { useChapter } from '../../../state';
import { useChapterContext } from '../../../Transcript/SpeakerSegment/ChapterContext';
import * as S from './styles';

export interface ChapterTitleProps {}

const ChapterTitle: React.FC<ChapterTitleProps> = () => {
  const { chapterId } = useChapterContext();
  const { data: chapter } = useChapter(chapterId);
  const { pushModal } = useModalsActions();

  const title = chapter?.title;

  const handleOpenChapterInfoModal = useCallback((): void => {
    pushModal({
      name: 'ChapterInfo',
      params: {
        chapterId,
      },
    });
  }, [chapterId, pushModal]);

  return (
    <Tooltip arrow contents="Edit chapter">
      <S.ChapterTitleButton onPress={handleOpenChapterInfoModal}>
        {title}
      </S.ChapterTitleButton>
    </Tooltip>
  );
};

export default ChapterTitle;
