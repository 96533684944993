import React from 'react';
import { useFeatureConfig } from './FeatureContext';
import { isComponentFeature } from './FeatureContext/selectors';
import { FeatureDataProvider } from './FeatureDataContext';
import { FeatureConfigPath } from './types';

type ControlledFeatureRenderFunction = (
  element: React.ReactNode,
) => React.ReactNode;

export type ControlledFeatureProps<TData = undefined> = {
  children?: ControlledFeatureRenderFunction;
  feature: FeatureConfigPath;
  data?: TData;
};

function getElementFromConfig(config: ReturnType<typeof useFeatureConfig>) {
  return config.isDisabled || !isComponentFeature(config)
    ? null
    : config.element;
}

/**
 * Renders element specified by the `feature` path in the feature config.
 *
 * If the feature element needs data that isn't already available via some state
 * or context, the `data` prop can be used and the feature element can consume
 * that data with `useFeatureData`.
 */
function ControlledFeature<TData = undefined>({
  children,
  data,
  feature,
}: ControlledFeatureProps<TData>) {
  const config = useFeatureConfig(feature);
  const element = getElementFromConfig(config);

  return (
    <FeatureDataProvider data={data}>
      {children ? children(element) : element}
    </FeatureDataProvider>
  );
}

export default ControlledFeature;
