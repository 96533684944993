import { isEqual, uniqWith } from 'lodash-es';
import { useCallback } from 'react';
import { DEFAULT_TEXT_ENTRY_COMPLETION_DELAY } from 'config';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useActivateWord from '../hooks/useActivateWord';
import useSearchInTranscript from '../hooks/useSearchInTranscript';
import { useWordsById } from '../state';
import { useEditorUiActions, useShowDeletedWords } from '../state/editorUi';
import useSearchState from './useSearchState';
import { SearchAction, SearchState } from './useSearchState/types';

interface UseSearch extends SearchState {
  dispatch: React.Dispatch<SearchAction>;
  hasResults: boolean;
  hasNoResults: boolean;
  isSearchInitialState: boolean;
  onQuery: (currentWord: string | string[]) => void;
  onUpdate: (newQuery: string, foundWordGroups: number[][]) => void;
}

/**
 * Handles searching for the Editor's Transcript, highlights found words and exposes
 * auxiliary props to be used outside.
 *
 * @returns
 * `dispatch`: Dispatches actions to the search state.
 * `hasResults`: True when the query returns a result.
 * `hasNoResults`: True when the query returns no results.
 * `isSearchInitialState`: Whether or not the search state in initial.
 * `onQuery`: A helper method that receives a query string and highlights the results in the transcript view.
 * It sets all the initial values to display the correct word highlight info for the user.
 * `onUpdate`: Updates the results list after updating texts.
 */
export function useSearch(): UseSearch {
  const { toggleDeletedWordsVisibility, selectWords } = useEditorUiActions();
  const { data: wordsById } = useWordsById();
  const { searchInTranscript } = useSearchInTranscript();
  const [state, dispatch] = useSearchState();
  const showDeletedWords = useShowDeletedWords();
  const activateWord = useActivateWord();

  /**
   * If deleted words are hidden & result matches a deleted
   * word then set deletions to visible.
   */
  const handleResultsDeletedWords = useCallback(
    (transcriptSearchResults: number[][]): void => {
      // Check if there are any deleted words.
      const hasDeletedWords = transcriptSearchResults
        .flat()
        .some((word) => wordsById?.[word]?.isDeleted);

      if (!showDeletedWords && hasDeletedWords) {
        toggleDeletedWordsVisibility();
      }
    },
    [showDeletedWords, toggleDeletedWordsVisibility, wordsById],
  );

  const searchWords = useDebouncedCallback(
    (currentQuery: string | string[]): void => {
      const transcriptSearchResults = searchInTranscript(currentQuery);

      dispatch({ type: 'SEARCH_FINISH', payload: transcriptSearchResults });

      if (!transcriptSearchResults.length) {
        return;
      }

      if (isEqual(transcriptSearchResults, state.foundWords) || !wordsById) {
        return;
      }

      const initialWordGroup = transcriptSearchResults[0];

      activateWord(wordsById?.[initialWordGroup[0]]);

      if (initialWordGroup.length > 1) {
        selectWords(initialWordGroup);
      }

      handleResultsDeletedWords(transcriptSearchResults);
    },
    { timeoutMillis: DEFAULT_TEXT_ENTRY_COMPLETION_DELAY },
  );

  const handleQuery = useCallback(
    (currentQuery: string | string[]): void => {
      dispatch({ type: 'SEARCH_BEGIN', payload: currentQuery });

      if (!currentQuery) {
        return;
      }

      return searchWords(currentQuery);
    },
    [dispatch, searchWords],
  );

  const handleUpdate = useCallback(
    (query: string, foundWordGroups: number[][]): void => {
      const transcriptSearchResults = searchInTranscript(query);

      if (!wordsById) {
        return;
      }

      dispatch({
        type: 'SEARCH_UPDATE',
        payload: {
          query,
          foundWords: uniqWith(
            [...foundWordGroups, ...transcriptSearchResults],
            isEqual,
          ).sort(
            (w1, w2) =>
              wordsById?.[w1[0]].startMillis - wordsById?.[w2[0]].startMillis,
          ),
        },
      });
    },
    [dispatch, searchInTranscript, wordsById],
  );

  return {
    ...state,
    dispatch,
    hasResults:
      !state.isSearching && !!state.query && !!state.foundWords.length,
    hasNoResults:
      !state.isSearching && !!state.query && !state.foundWords.length,
    isSearchInitialState:
      !state.isSearching && !state.query && !state.showFillerWords,
    onQuery: handleQuery,
    onUpdate: handleUpdate,
  };
}
