import {
  getThemeColor,
  Text,
  Button as BlockheadButton,
  VideoOutline,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Label = styled(Text).attrs({
  variant: 'mono',
})`
  color: ${getThemeColor('l2')};
  text-transform: lowercase;
  font-size: 16px;
  line-height: 130%;
`;

export const Button = styled(BlockheadButton).attrs({ size: 'small' })`
  height: 32px;
  flex: 1 1 auto;
`;

export const VideoIcon = styled(VideoOutline)`
  width: 16px;
  color: ${getThemeColor('l2')};
`;
