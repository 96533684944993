import {
  ProBadgeGradient,
  Spacer,
  Tooltip,
  TooltipProps,
} from '@sparemin/blockhead';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { baseAdjustmentsBarAnimationProps } from './animation';
import Footer from './Footer';
import * as S from './styles';

export interface BaseAdjustmentsBarProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const BaseAdjustmentsBar: React.FC<BaseAdjustmentsBarProps> = ({
  title,
  description = 'This will overwrite previous assets, so make sure to download any that you want to keep first.',
  children,
}) => {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  // The Tooltip component interferes with click handling inside its contents,
  // especially for inputs. Typically, wrapping the contents inside a `div`
  // resolves this issue, but it was ineffective in this case. To solve this
  // problem, the wrapper for the contents is conditionally chosen so that the
  // tooltip only acts as a wrapper when necessary. Since the tooltip is only
  // needed when the contents should be disabled (e.g., when user access is
  // gated), there are no issues with click handling in this scenario.
  const ContentsWrapper = isBasicOrFreeTier ? Tooltip : 'div';

  const contentsWrapperProps: TooltipProps = isBasicOrFreeTier
    ? {
        arrow: true,
        isDisabled: !isBasicOrFreeTier,
        placement: 'top',
        contents: 'PRO feature: upgrade to unlock',
      }
    : ({} as TooltipProps);

  return (
    <S.Root {...baseAdjustmentsBarAnimationProps}>
      <Spacer orientation="vertical" space="24px">
        <Spacer orientation="vertical" space="4px">
          {title && (
            <S.Title>
              {title}{' '}
              {isBasicOrFreeTier && <ProBadgeGradient width={28} height={15} />}
            </S.Title>
          )}

          {description && <S.Description>{description}</S.Description>}
        </Spacer>

        <S.Container>
          <ContentsWrapper {...contentsWrapperProps}>
            <S.Contents $isGated={Boolean(isBasicOrFreeTier)}>
              {children}
            </S.Contents>
          </ContentsWrapper>
        </S.Container>

        <Footer />
      </Spacer>
    </S.Root>
  );
};

export default BaseAdjustmentsBar;
