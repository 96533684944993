import { produce } from 'immer';
import { get, keyBy, transform } from 'lodash-es';
import memoize from 'memoizee';

import {
  ClipSuggestion,
  ClipSuggestionGroup,
  ColorPaletteResult,
  EdgeAssetType,
  Project,
  ProjectNameSuggestionResult,
  ProjectPublicAsset,
  ProjectTranscript,
  PromoPackAssetType,
  PromoPackContentsResult,
  PromoPacksResult,
  PublicAsset,
  SpeakersResult,
  TranscriptShowNoteSuggestions,
  Waveform,
} from 'api/services';
import { isOptInAsset } from 'pages/TranscriptEditorPage/PromoPackModal/utils';
import { AspectRatioName } from 'types';
import { getAspectRatioNameFromDimensions } from 'utils/aspectRatio';
import {
  IndexedClipSuggestion,
  PromoPackAssetStatus,
  PromoPackContentByType,
} from '../types';
import collapseIntervals from './collapseIntervals';
import createWordDictionary from './createWordDictionary';
import {
  ChaptersBySegmentsDictionary,
  ProjectPublicAssetsUrls,
  SegmentsDictionary,
} from './types';

function createSegmentDictionary(transcript: ProjectTranscript) {
  const { segments } = transcript;
  const result: SegmentsDictionary = {};

  for (let i = 0; i < segments.length; i += 1) {
    const segment = segments[i];
    result[segment.id] = {
      ...segment,
      index: i,
    };
  }

  return result;
}

function createWordList(transcript: ProjectTranscript) {
  return transcript.segments.flatMap((s) => s.words);
}

function createWordIdList(transcript: ProjectTranscript) {
  const wordList = wordListSelector(transcript);
  return wordList.map((w) => w.id);
}

function createChaptersDictionary(transcript: ProjectTranscript) {
  return keyBy(chaptersSelector(transcript), 'id');
}

// If any word id in a segment matches the startWordId of a chapter, the
// chapter is considered to belong to that segment.
function createChaptersBySegmentsDictionary(d: ProjectTranscript) {
  const { segments, chapters } = d;
  const wordsById = wordDictionarySelector(d);

  const result: ChaptersBySegmentsDictionary = {};

  segments.forEach((segment) => {
    const segmentFirstWordId = segment.words[0].id;
    const segmentLastWordId = segment.words[segment.words.length - 1].id;
    const segmentFirstWordIndex = wordsById[segmentFirstWordId].index;
    const segmentLastWordIndex = wordsById[segmentLastWordId].index;

    result[segment.id] = chapters.filter((chapter) => {
      const chapterStartWordIndex = wordsById[chapter.startWordId].index;

      return (
        chapterStartWordIndex >= segmentFirstWordIndex &&
        chapterStartWordIndex <= segmentLastWordIndex
      );
    });
  });

  return result;
}

export const transcriptIdSelector = (d: ProjectTranscript) => d.id;

export const segmentIdsSelector = (d: ProjectTranscript) =>
  d.segments.map((s) => s.id);

export const projectCreationProgressSelector = (d: Project) =>
  d.progress.percentMilli;

export const projectTranscriptSelector = (d: Project) => d.projectTranscript;

export const projectCuidSelector = (d: Project) => d.projectCuid;

export const projectNameSelector = (d: Project) => d.name;

export const projectMediaTypeSelector = (d: Project) =>
  d.associatedMedias[0].mediaType;

export const projectAudioSelector = (d: Project) =>
  d.associatedMedias.find((m) => m.mediaType === 'audio');

export const projectAssetUrlSelector = (d: Project) =>
  d.associatedMedias[0].assetUrl;

export const projectWaveformUrlSelector = (d: Project) =>
  d.associatedMedias[0]?.waveformData?.waveformDataUrl;

export const projectUrlTraceIdSelector = (d: Project) => d.traceId;

export const projectStatusSelector = (d: Project) => d.status;

export const isNameSuggestionAppliedSelector = (d: Project) =>
  d.isNameSuggestionApplied;

export const isFillerWordsRemovedSelector = (d: Project) =>
  d.isFillerWordsRemoved;

export const hasSubmittedRelatedPodcastSelector = (d: Project) =>
  d.hasSubmittedRelatedPodcast;

export const collapsedIntervalsSelector = memoize(collapseIntervals, {
  max: 1,
});

export const segmentDictionarySelector = memoize(createSegmentDictionary, {
  max: 1,
});

export const wordDictionarySelector = memoize(createWordDictionary, { max: 1 });

export const wordListSelector = memoize(createWordList, { max: 1 });

export const wordIdListSelector = memoize(createWordIdList, { max: 1 });

export const peaksSelector = (d: Waveform) => d.data;

export const suggestionsSelector = (d: ClipSuggestionGroup) => d.suggestions;

export const suggestionsStatusSelector = (d: ClipSuggestionGroup) => d.status;

export const suggestionSelector = (
  d: ClipSuggestionGroup,
  suggestionId: number,
) => {
  const suggestions = suggestionsSelector(d);
  return suggestions?.find((s) => s.id === suggestionId);
};

export const suggestionCountSelector = (d: ClipSuggestionGroup) => {
  const suggestions = suggestionsSelector(d);
  return suggestions?.length;
};

export const createIndexedClipSuggestions = (
  transcript: ProjectTranscript,
  suggestions: ClipSuggestion[] | undefined,
) => {
  if (!suggestions) {
    return undefined;
  }

  const wordsById = wordDictionarySelector(transcript);
  const indexedSuggestions = produce(
    suggestions as IndexedClipSuggestion[],
    (draft) => {
      draft.forEach((suggestion) => {
        suggestion.startWordIndex = wordsById[suggestion.startWordId].index;
        suggestion.endWordIndex = wordsById[suggestion.endWordId].index;
      });
    },
  );
  return [...indexedSuggestions].sort(
    (s1, s2) => s1.startWordIndex - s2.startWordIndex,
  );
};

export const indexedClipSuggestionsSelector = memoize(
  createIndexedClipSuggestions,
  { max: 1 },
);

export const segmentInitialWordIndexSelector = (
  d: ProjectTranscript,
  segmentId: number,
) => {
  const segmentsById = segmentDictionarySelector(d);
  const wordsById = wordDictionarySelector(d);
  const segment = segmentsById[segmentId];
  const firstWordInSegment = segment?.words?.[0];

  return wordsById[firstWordInSegment?.id]?.index ?? 0;
};

export const orderedClipSuggestionIdsSelector = (
  transcript: ProjectTranscript,
  suggestions: ClipSuggestion[] | undefined,
) => {
  const orderedSuggestions = indexedClipSuggestionsSelector(
    transcript,
    suggestions,
  );
  return orderedSuggestions?.map((s) => s.id);
};

export const chaptersSelector = memoize(
  (d: ProjectTranscript) =>
    [...d.chapters].sort((c1, c2) => c1.startMillis - c2.startMillis),
  { max: 1 },
);

export const canInsertChapterAtWordSelector = (
  d: ProjectTranscript,
  wordId: number,
) => {
  const chapters = chaptersSelector(d);
  return chapters.every((c) => c.startWordId !== wordId);
};

export const chaptersDictionarySelector = memoize(createChaptersDictionary, {
  max: 1,
});

export const chaptersBySegmentsDictionarySelector = memoize(
  createChaptersBySegmentsDictionary,
  { max: 1 },
);

export const firtSuggestedShowNoteSelector = (
  d: TranscriptShowNoteSuggestions,
) => d?.showNotesSuggestions[0]?.showNotes ?? '';

export const projectNameSuggestionsSelector = (
  d: ProjectNameSuggestionResult,
) => (d.suggestions ?? []).map((suggestion) => suggestion.name);

export const createSuggestedProjectNamesSelector = (
  d: ProjectNameSuggestionResult,
  resultsAmount: number,
) => {
  const suggestions = projectNameSuggestionsSelector(d);
  return suggestions.slice(0, resultsAmount);
};

export const firstNProjectNamesSuggestionsSelector = memoize(
  createSuggestedProjectNamesSelector,
  { max: 1 },
);

export const hasPromoPacksSelector = (d: PromoPacksResult) => {
  return !!d.promoPacks.length;
};

export const promoPackStatusSelector = (d?: PromoPacksResult) => {
  return d?.promoPacks?.[0]?.status;
};

export const promoPackSuccessSelector = (d?: PromoPacksResult) => {
  return promoPackStatusSelector(d) === 'completed';
};

export const promoPackIsProcessingSelector = (d?: PromoPacksResult) => {
  const promoPackStatus = promoPackStatusSelector(d);

  return d?.promoPacks?.length && promoPackStatus === 'processing';
};

export const promoPackContentsByTypeSelector = (
  d?: PromoPackContentsResult,
) => {
  const promoPackContentsByType: PromoPackContentByType =
    {} as PromoPackContentByType;

  d?.assets.map((asset) => {
    return (promoPackContentsByType[asset.assetType] = asset);
  });

  return promoPackContentsByType;
};

export const promoPackAssetSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  const promoPackContentsByType = promoPackContentsByTypeSelector(d);

  return promoPackContentsByType?.[assetType];
};

export const promoPackAssetStatusSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
): PromoPackAssetStatus | undefined => {
  if (!assetType) {
    return;
  }

  const asset = promoPackAssetSelector(d, assetType);
  const isAssetError =
    asset?.status === 'error' ||
    (!isOptInAsset(assetType) && asset?.status === 'notFound');
  const isAssetLoading = asset?.status === 'processing';
  return { isAssetError, isAssetLoading } as PromoPackAssetStatus;
};

export const promoPackAssetContentSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  const asset = promoPackAssetSelector(d, assetType);

  return asset?.contents?.[0];
};

export const promoPackContentTypeSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  const asset = promoPackAssetSelector(d, assetType);

  return asset?.contentType;
};

export const promoPackAssetTextContentSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  return promoPackAssetContentSelector(d, assetType)?.text;
};

export const promoPackAssetDisplayTextSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  return promoPackAssetContentSelector(d, assetType)?.displayText;
};

export const promoPackAssetHTMLContentSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  return promoPackAssetContentSelector(d, assetType)?.html;
};

export const promoPackAssetDisplayHtmlSelector = (
  d: PromoPackContentsResult,
  assetType?: PromoPackAssetType,
) => {
  if (!assetType) {
    return;
  }

  return promoPackAssetContentSelector(d, assetType)?.displayHtml;
};

export const speakersNamesListSelector = (d: SpeakersResult) => {
  return d?.speakers?.map(({ name }) => name);
};

export const edgeAssetSelector = (d: Project, assetType: EdgeAssetType) =>
  d.edgeAssets?.[assetType];

export const edgeAssetThumbnailSelector = (
  d: Project,
  variant: EdgeAssetType,
) => edgeAssetSelector(d, variant)?.thumbnail;
export const projectPublicAssetsUrlsSelector = (d: ProjectPublicAsset) => {
  return transform(
    d,
    (
      result: ProjectPublicAssetsUrls,
      assetList: PublicAsset[],
      key: keyof ProjectPublicAsset,
    ) => (result[key] = get(assetList, '[0].url') ?? ''),
    {} as ProjectPublicAssetsUrls,
  );
};

export const hexColorPaletteListSelector = (d: ColorPaletteResult) => {
  return d.colors?.map(({ hexColorCode }) => `#${hexColorCode}`);
};

export const episodeArtAspectRatioNameSelector = (
  d: PromoPackContentsResult,
): AspectRatioName | undefined => {
  const episodeArt = promoPackAssetContentSelector(d, 'episodeArt');

  return getAspectRatioNameFromDimensions(
    episodeArt?.imageWidth,
    episodeArt?.imageHeight,
  );
};

export const hasEdgeAssetSelector = (d: Project, variant: EdgeAssetType) => {
  const edgeAsset = edgeAssetSelector(d, variant);
  return edgeAsset?.status === 'completed';
};

export const edgeAssetStatusSelector = (d: Project, variant: EdgeAssetType) => {
  const edgeAsset = edgeAssetSelector(d, variant);
  return edgeAsset?.status;
};

export const edgeAssetIdSelector = (d: Project, variant: EdgeAssetType) => {
  const edgeAsset = edgeAssetSelector(d, variant);
  return edgeAsset?.id;
};
