import { kebabCase } from 'lodash-es';

export const WORD_FONT_SIZE_PX = 16;
export const WORD_LINE_HEIGHT = 2;

export enum WordDatasetKey {
  ID = 'wordId',
}

export const WORD_ID_DATA_ATTRIBUTE = `data-${kebabCase(WordDatasetKey.ID)}`;
