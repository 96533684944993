import { useContext } from 'react';
import NativeTranscriptEditorPageContext from './NativeTranscriptEditorPageContext';

export default function useNativeTranscriptEditorPageContext() {
  const context = useContext(NativeTranscriptEditorPageContext);

  if (context === undefined) {
    throw new Error(
      'useNativeTranscriptEditorPageContext must be used within NativeTranscriptEditorPageProvider',
    );
  }

  return context;
}
