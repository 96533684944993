import {
  EMBED_TRANSCRIPT_EDITOR_CONTEXT_PARAM_NAME,
  EMBED_TRANSCRIPT_EDITOR_MARGIN_PARAM_NAME,
  EMBED_TRANSCRIPT_EDITOR_START_MILLIS_PARAM_NAME,
  EMBED_TRANSCRIPT_EDITOR_END_MILLIS_PARAM_NAME,
} from './constants';

export function getContext(searchParams: URLSearchParams) {
  const val = searchParams.get(EMBED_TRANSCRIPT_EDITOR_CONTEXT_PARAM_NAME);

  if (val === 'default' || val === 'inline') {
    return val;
  }

  return 'default';
}

export function getMargin(searchParams: URLSearchParams) {
  const shorthand = searchParams.get(EMBED_TRANSCRIPT_EDITOR_MARGIN_PARAM_NAME);

  if (!shorthand) {
    return undefined;
  }

  const pxValues = shorthand.split(',').map(parseFloat);

  const top = pxValues[0];
  const right = pxValues[1] ?? top;
  const bottom = pxValues[2] ?? top;
  const left = pxValues[3] ?? right;

  return { bottom, left, right, top };
}

export function getTranscriptTimeRange(searchParams: URLSearchParams) {
  const startMillis = searchParams.get(
    EMBED_TRANSCRIPT_EDITOR_START_MILLIS_PARAM_NAME,
  );
  const endMillis = searchParams.get(
    EMBED_TRANSCRIPT_EDITOR_END_MILLIS_PARAM_NAME,
  );

  return {
    startMillis: startMillis !== null ? Number(startMillis) : undefined,
    endMillis: endMillis !== null ? Number(endMillis) : undefined,
  };
}
