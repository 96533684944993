import { SearchField } from '@sparemin/blockhead';
import { useCallback, useRef } from 'react';

import ExplorerBar from 'components/ExplorerBar';
import { FILLER_WORDS } from '../constants';
import { SEARCH_ACTION_MENU_MIN_FOUND_WORDS } from './constants';
import FillerWords from './FillerWords';
import NoResults from './NoResults';
import SearchActionMenu from './SearchActionMenu/SearchActionMenu';
import ShowFillerWordsButton from './ShowFillerWordsButton';
import * as S from './styles';
import useRegisterSearchKeyBindings from './useRegisterSearchKeyBindings';
import { useSearch } from './useSearch';
import { useSearchControls } from './useSearchControls';

export interface SearchBarProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  isOpen,
  onClose,
  onOpen,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    dispatch,
    hasResults,
    hasNoResults,
    isSearchInitialState,
    onQuery,
    onUpdate,
    ...state
  } = useSearch();

  const { onToggleFillerWords, onPrev, onNext } = useSearchControls({
    state,
    dispatch,
  });

  const { query, foundWords, activeWordIndex, showFillerWords } = state;

  const handleCloseSearch = useCallback((): void => {
    inputRef.current?.blur();
    onClose();
  }, [onClose]);

  const handleOpenSearch = useCallback((): void => {
    inputRef.current?.focus();
    onOpen();
  }, [onOpen]);

  const createHandleToggleFillerWords =
    (shouldShowFillerWords: boolean) => () => {
      onToggleFillerWords(shouldShowFillerWords);

      if (shouldShowFillerWords) {
        onQuery(FILLER_WORDS);
      }
    };

  const handleOnUpdate = useCallback(
    (newQuery: string, foundWordGroups: number[][]) => {
      onToggleFillerWords(false);
      onUpdate(newQuery, foundWordGroups);
    },
    [onToggleFillerWords, onUpdate],
  );

  useRegisterSearchKeyBindings({
    isOpen,
    onToggleOn: handleOpenSearch,
    onToggleOff: onClose,
    onNextClick: onNext,
    onPreviousClick: onPrev,
  });

  return (
    <S.Root {...{ className, isOpen }} onClose={handleCloseSearch}>
      <S.SearchField
        value={Array.isArray(query) ? query.join(',') : query}
        placeholder="Search your transcript"
        onChange={onQuery}
        onFocus={handleOpenSearch}
      >
        <SearchField.SearchIcon />

        <S.InputContainer $isOpen={isOpen} $show={!showFillerWords}>
          <ExplorerBar.Input ref={inputRef} />
        </S.InputContainer>

        <S.FillerWordsContainer $show={isOpen && showFillerWords}>
          <FillerWords onClose={createHandleToggleFillerWords(false)} />
        </S.FillerWordsContainer>

        {isOpen && (
          <S.Controllers>
            {isSearchInitialState && (
              <ShowFillerWordsButton
                onPress={createHandleToggleFillerWords(true)}
              />
            )}

            {hasResults && (
              <>
                <ExplorerBar.ResultsCount
                  position={activeWordIndex + 1}
                  total={foundWords.length}
                />
                <ExplorerBar.ArrowControls onPrev={onPrev} onNext={onNext} />

                {foundWords.length >= SEARCH_ACTION_MENU_MIN_FOUND_WORDS && (
                  <SearchActionMenu
                    {...{ foundWords, activeWordIndex }}
                    onDelete={handleCloseSearch}
                    onUpdate={handleOnUpdate}
                  />
                )}
              </>
            )}

            {hasNoResults && <NoResults />}

            <SearchField.ClearButton onPress={handleCloseSearch} />
          </S.Controllers>
        )}
      </S.SearchField>
    </S.Root>
  );
};

export default SearchBar;
