import { useContext } from 'react';
import HeaderContext from './HeaderContext';

export default function useHeaderContext() {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error('useHeaderContext must be used within HeaderProvider');
  }

  return context;
}
