import { ButtonProps } from '@sparemin/blockhead';
import React from 'react';
import IntervalDurationButton from 'pages/TranscriptEditorPage/IntervalDurationButton';
import useClipSuggestionWords from '../useClipSuggestionWords';

export type SuggestedClipPrimaryActionButtonProps = Omit<ButtonProps, 'size'>;

const SuggestedClipPrimaryActionButton = React.forwardRef<
  HTMLButtonElement,
  SuggestedClipPrimaryActionButtonProps
>((props, ref) => {
  const [startWord, endWord] = useClipSuggestionWords();

  return (
    <IntervalDurationButton
      {...props}
      ref={ref}
      interval={{
        startMillis: startWord?.startMillis,
        endMillis: endWord?.endMillis,
      }}
    />
  );
});

export default SuggestedClipPrimaryActionButton;
