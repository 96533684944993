import styled from 'styled-components';
import BasePage from 'components/Page';

type PageStyleProps = {
  $hasNav: boolean;
};

export const Page = styled(BasePage)<PageStyleProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $hasNav, theme }) =>
    !$hasNav ? 0 : theme.headerHeightDesktop + theme.spacing.base * 2}px;
`;
