import { Link } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import {
  PRO_TIER_MAX_MEDIA_DURATION_HOURS,
  PRO_TIER_MAX_MEDIA_SIZE_GB,
  PRO_TIER_MAX_MONTHLY_TRANSCRIPTION_DURATION_HOURS,
} from 'config';
import { useModalsActions } from 'state/modal';
import {
  usePlanDurationLimitHours,
  usePlanMaxUploadMb,
  usePlanMonthlyDurationLimitHours,
} from 'state/pricing';
import useCanRenderUploadLimits from './useCanRenderUploadLimits';

type BaseUploadLimitsProps = {
  /**
   * By default, the limits and upgrade messages are separated by a line break.
   * If `isInline` is true, the line break is omitted.
   */
  isInline?: boolean;
  /**
   * If true, will render the "upsell" portion of the message, informing the
   * user of what they will get if they upgrade
   */
  isUpsell?: boolean;
  upgradeLabel?: string;
};

type UploadLimitsWithoutUpsellProps = BaseUploadLimitsProps & {
  isUpsell?: false;
};

type UploadLimitsWithUpsellProps = BaseUploadLimitsProps & {
  /**
   * If true, will provide a link for a user to upgrade their plan.  Has no
   * effect if `isUpsell` is false.
   */
  canUpgrade?: boolean;
  isUpsell: true;
};

export type UploadLimitsMessageProps =
  | UploadLimitsWithUpsellProps
  | UploadLimitsWithoutUpsellProps;

function getHoursString(val: number) {
  if (val === Infinity) {
    return 'unlimited';
  }

  return val === 1 ? `${val} hour` : `${val} hours`;
}

const PRO_MONTHLY_DURATION_STR = getHoursString(
  PRO_TIER_MAX_MONTHLY_TRANSCRIPTION_DURATION_HOURS,
);

const PRO_FILE_DURATION_STR = getHoursString(PRO_TIER_MAX_MEDIA_DURATION_HOURS);

const UploadLimitsMessage: React.FC<UploadLimitsMessageProps> = (props) => {
  const { isInline, isUpsell } = props;
  const { canUpgrade = false, upgradeLabel = 'Upgrade today' } =
    props as UploadLimitsWithUpsellProps;

  const { pushModal } = useModalsActions();
  const { data: maxFileUploadMb } = usePlanMaxUploadMb();
  const { data: fileDurationLimitHours } = usePlanDurationLimitHours();
  const { data: monthlyDurationLimitHours } =
    usePlanMonthlyDurationLimitHours();

  const canRenderLimitsMessage = useCanRenderUploadLimits();

  const fileDurationStr =
    fileDurationLimitHours === undefined
      ? undefined
      : getHoursString(fileDurationLimitHours);

  const monthlyDurationStr =
    monthlyDurationLimitHours === undefined
      ? undefined
      : getHoursString(monthlyDurationLimitHours);

  const handleUpgrade = useCallback(() => {
    pushModal({
      name: 'Upsell',
      params: {
        context: 'uploadLimits',
      },
    });
  }, [pushModal]);

  const upgradeLink = !canUpgrade ? (
    upgradeLabel
  ) : (
    <Link as="button" onPress={handleUpgrade}>
      {upgradeLabel}
    </Link>
  );

  return !canRenderLimitsMessage ? null : (
    <>
      Your current plan only permits files up to {maxFileUploadMb}MB and{' '}
      {fileDurationStr} long, with a monthly limit of {monthlyDurationStr}.{' '}
      {!isInline && <br />}
      {isUpsell && (
        <>
          {upgradeLink} for {PRO_MONTHLY_DURATION_STR} monthly uploads and files
          up to {PRO_TIER_MAX_MEDIA_SIZE_GB}GB and {PRO_FILE_DURATION_STR} long.
        </>
      )}
    </>
  );
};

export default UploadLimitsMessage;
