import {
  PopoverContainer,
  Overlay,
  PopoverContainerProps,
} from '@sparemin/blockhead';
import React from 'react';

export type PopoverProps = Pick<
  PopoverContainerProps,
  | 'isOpen'
  | 'offset'
  | 'triggerElement'
  | 'children'
  | 'placement'
  | 'preventOverflow'
  | 'updatePositionOnTriggerResize'
>;

const Popover: React.FC<PopoverProps> = ({ children, ...props }) => {
  return (
    <PopoverContainer {...props}>
      <Overlay allowClickthrough preventScrolling>
        {children}
      </Overlay>
    </PopoverContainer>
  );
};
export default Popover;
