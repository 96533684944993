import { Edit, IconButtonProps, PressEvent } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import ActionButton from '../../ActionButton';

export interface EditTextActionButtonProps
  extends Pick<IconButtonProps, 'onPress'> {}

const EditTextActionButton: React.FC<EditTextActionButtonProps> = ({
  onPress,
}) => {
  const { trackHighlightPopperClicked } = useEventTracking();
  const handlePress = useCallback(
    (e: PressEvent) => {
      trackHighlightPopperClicked({ buttonClick: 'CorrectText' });
      onPress?.(e);
    },
    [onPress, trackHighlightPopperClicked],
  );
  return (
    <ActionButton
      aria-label="correct text"
      onPress={handlePress}
      tooltip="Correct text"
    >
      <ActionButton.Icon as={Edit} />
    </ActionButton>
  );
};

export default EditTextActionButton;
