import { ButtonProps } from '@sparemin/blockhead';
import React from 'react';
import { TimeInterval } from 'types';
import { formatIntervalDuration } from 'utils/time';

import * as S from './styles';

export type IntervalDurationButtonProps = Pick<
  ButtonProps,
  'aria-label' | 'onPress'
> & {
  interval: number | Partial<TimeInterval>;
  children?: React.ReactNode;
};

const IntervalDurationButton = React.forwardRef<
  HTMLButtonElement,
  IntervalDurationButtonProps
>(
  (
    {
      'aria-label': ariaLabel = 'create clip',
      onPress,
      children,
      interval,
    }: IntervalDurationButtonProps,
    ref,
  ) => {
    const duration =
      typeof interval === 'number'
        ? interval
        : formatIntervalDuration(interval?.startMillis, interval?.endMillis);

    const contents = duration ?? children;

    return (
      <S.Button
        aria-label={ariaLabel}
        onPress={onPress}
        startIcon={<S.VideoIcon />}
        ref={ref}
      >
        <S.Label>{contents}</S.Label>
      </S.Button>
    );
  },
);

export default IntervalDurationButton;
