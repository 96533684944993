import { parseToRgba, rgba } from 'color2k';

export function transparent(color: string) {
  const [r, g, b] = parseToRgba(color);
  return rgba(r, g, b, 0);
}

export function opaque(color: string) {
  const [r, g, b] = parseToRgba(color);
  return rgba(r, g, b, 1);
}
