import { Button } from '@sparemin/blockhead';
import React from 'react';
import { useHeaderContext } from 'pages/TranscriptEditorPage/Header/HeaderContext';
import { useEditorTranscript, useProjectCuid } from '../../state';
import useOpenExportModal from './useOpenExportModal';

export interface ExportProjectButtonProps {}

const ExportProjectButton: React.FC<ExportProjectButtonProps> = () => {
  const { canExport } = useHeaderContext();
  const { isLoading: projectIsLoading } = useProjectCuid();
  const { isLoading: transcriptIsLoading } = useEditorTranscript();
  const { canOpenExportModal, openExportModal } = useOpenExportModal();

  return (
    <Button
      isDisabled={
        !canExport ||
        !canOpenExportModal ||
        projectIsLoading ||
        transcriptIsLoading
      }
      onPress={openExportModal}
    >
      export
    </Button>
  );
};

export default ExportProjectButton;
