import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  actionsSelector,
  activeClipIndexSelector,
  explorerSelector,
} from './selectors';
import { Explorer, ExplorerBarState, ExplorerBarStore } from './types';

const defaultState: ExplorerBarState = {
  activeClipIndex: 0,
  explorer: undefined,
};

const useExplorerBarStore = create(
  immer<ExplorerBarStore>((set) => ({
    ...defaultState,
    actions: {
      activateClipIndex(index) {
        set((store) => {
          store.activeClipIndex = index;
        });
      },
      openExplorer(explorer) {
        set((store) => {
          store.explorer = explorer;
        });
      },
      closeExplorer() {
        set((store) => {
          store.explorer = undefined;
        });
      },
      reset() {
        set(defaultState);
      },
    },
  })),
);

export const useExplorerBarActions = () => useExplorerBarStore(actionsSelector);

export const useActiveExplorer = () => useExplorerBarStore(explorerSelector);

export const useIsExplorerActive = (explorer: Explorer) => {
  const activeExplorer = useActiveExplorer();
  return activeExplorer === explorer;
};

export const useActiveClipIndex = () =>
  useExplorerBarStore(activeClipIndexSelector);
