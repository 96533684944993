import { getThemeColor, RAIL_CLASSNAME, Slider } from '@sparemin/blockhead';
import styled from 'styled-components';
import BasePlaybackTime from 'components/PlaybackTime';

export const Progress = styled(Slider)`
  align-items: center;
  display: flex;
  flex-grow: 1;

  .${RAIL_CLASSNAME} {
    background-color: ${getThemeColor('l5')};
  }
`;

export const PlaybackTime = styled(BasePlaybackTime).attrs({
  forwardedAs: 'output',
})`
  margin: ${({ theme }) => theme.spacing.create(0, 0, 0, 3)};
`;
