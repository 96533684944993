import React from 'react';
import { usePromoPackAsset } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import { BaseAdjustmentsBarProps } from '../BaseAdjustmentsBar/BaseAdjustmentsBar';
import { EpisodeArtRegenerationOptions } from '../EpisodeArtTabPanel';
import StructuredAdjustmentsBar from '../StructuredAdjustmentsBar';

export interface EpisodeArtAdjustmentsBarProps
  extends Omit<BaseAdjustmentsBarProps, 'children'> {
  placeholder?: string;
}

const EpisodeArtAdjustmentsBar: React.FC<
  EpisodeArtAdjustmentsBarProps
> = () => {
  const { data: asset } = usePromoPackAsset('episodeArt');
  if (asset?.status === 'notFound')
    return (
      <StructuredAdjustmentsBar
        title="Generate episode art"
        description="We’ll visualize your episode content based on your transcript and any direction you provide below."
        regenerationOptions={<EpisodeArtRegenerationOptions />}
      />
    );

  return (
    <StructuredAdjustmentsBar
      title="Adjust Episode Art"
      regenerationOptions={<EpisodeArtRegenerationOptions />}
    />
  );
};
export default EpisodeArtAdjustmentsBar;
