import React, { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { CreateClipButton } from '../../EditorContextMenu';
import { useProjectCuid } from '../../state';
import { useWordsContextMenuConfig } from '../../state/contextMenu';

export interface AudiogramContextMenuButtonProps {}

const AudiogramContextMenuButton: React.FC<
  AudiogramContextMenuButtonProps
> = () => {
  const config = useWordsContextMenuConfig();
  const { data: projectCuid } = useProjectCuid();
  const { pushModal } = useModalsActions();
  const { trackHighlightPopperClicked } = useEventTracking();

  const handleExport = useCallback(async () => {
    if (!config || !projectCuid) {
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'MakeAudiogram' });
    const { words } = config;

    pushModal({
      name: 'AudiogramExport',
      params: {
        words,
      },
    });
  }, [config, projectCuid, pushModal, trackHighlightPopperClicked]);

  return <CreateClipButton onPress={handleExport} />;
};

export default AudiogramContextMenuButton;
