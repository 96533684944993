import { Heading } from '@sparemin/blockhead';
import { useRef } from 'react';
import SpinningSparklyHeady from 'assets/spinning-sparkly-heady.gif';
import { regeneratingViewAnimationProps } from './animation';
import * as S from './styles';

export interface RegeneratingViewProps {
  variant?: 'generating' | 'regenerating';
}

const RegeneratingView: React.FC<RegeneratingViewProps> = ({
  variant = 'regenerating',
}) => {
  const isGeneratingRef = useRef(variant === 'generating');

  return (
    <S.Root {...regeneratingViewAnimationProps}>
      <img
        src={SpinningSparklyHeady}
        alt="sparkly heady"
        width="134px"
        height="134px"
      />

      <Heading level={1}>
        {isGeneratingRef.current
          ? 'Generating your assets'
          : 'Regenerating your assets'}
      </Heading>

      {isGeneratingRef.current ? null : (
        <Heading level={2}>
          We’ll remember this feedback to improve the quality next time!
        </Heading>
      )}
    </S.Root>
  );
};

export default RegeneratingView;
