import { get } from 'lodash-es';
import { SetRequired } from 'type-fest';
import {
  ComponentFeatureConfig,
  FeatureConfigPath,
  TranscriptEditorPageFeatureConfig,
} from '../types';

function createPath(...segments: string[]) {
  return segments.join('.');
}

function getPathSegments(path: string) {
  return path.split('.');
}

/*
 * given a path list, returns a list of paths for the target key and each of
 * its ancestors.
 *
 * For example, given `a.b.c`, this function will return `['a', 'a.b', 'a.c']`.
 * The list of paths can be used to visit each ancestor when processing the
 * configuration
 */
function getAncestorPathList(path: FeatureConfigPath) {
  return getPathSegments(path).reduce((list, segment) => {
    if (list.length === 0) {
      return [segment] as FeatureConfigPath[];
    }

    const prevPath = list[list.length - 1];
    const newPath = createPath(prevPath, segment) as FeatureConfigPath;

    list.push(newPath);

    return list;
  }, [] as FeatureConfigPath[]);
}

export function isComponentFeature(
  config: object,
): config is SetRequired<ComponentFeatureConfig, 'element'> {
  return (config as ComponentFeatureConfig).element !== undefined;
}

export const featureSelector = <Path extends FeatureConfigPath>(
  config: TranscriptEditorPageFeatureConfig,
  path: Path,
) => {
  const featureConfig = get(config, path);
  const pathList = getAncestorPathList(path);

  const isDisabled = pathList.some((ancestorPath) => {
    const isDisabledPath = createPath(ancestorPath, 'isDisabled');
    return get(config, isDisabledPath) ?? false;
  });

  return {
    ...featureConfig,
    isDisabled,
  };
};
