import { camelCase, isString } from 'lodash-es';

export function removeFileExt(value: string) {
  // https://stackoverflow.com/a/4250408
  return value.replace(/\.[^/.]+$/, '');
}

export function capitalize(str: string) {
  if (!isString(str)) return str;
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function pascalCase(str: string) {
  return capitalize(camelCase(str));
}

export function getFirstLetter(str: string): string {
  return str.trim().slice(0, 1);
}

/**
 * takes a multiline string and concatenates it into a single line
 */
export function collapse(str: string, newlineReplacment = ' ') {
  return str.replace(/\n/g, newlineReplacment).trim();
}
