import { Button, Heading, Spacer, HeadySparkle } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import notifyError from 'components/notification';
import { useEventTracking } from 'state/eventTracking';
import { EddyPref } from 'state/userPref/types';
import AIPodcastSearchModal from '../AIPodcastSearchModal';
import AIOptionsList from './AIOptionsList';
import * as S from './style';
import useUpdateAIOptions from './useUpdateAIOptions';

export interface AIOptOutProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  allowPodcastSearch?: boolean;
  className?: string;
  onSubmit?: (prefs?: EddyPref) => void;
}

const AIOptOut: React.FC<AIOptOutProps> = ({
  title = 'How can Headliner AI help?',
  description = 'We can automatically do the following work for you:',
  allowPodcastSearch,
  className,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isLoading,
    onSubmit: onUpdateAIOptionsSubmit,
    ...aiOptionsListProps
  } = useUpdateAIOptions();

  const { defaultPodcast, prefs } = aiOptionsListProps;

  const defaultPodcastTitle = defaultPodcast?.title;

  const { trackAiSelections } = useEventTracking();

  const handleSubmit = useCallback(async () => {
    trackAiSelections({
      nameProject: prefs?.isAutoProjectNameEnabled,
      addChapters: prefs?.isAutoChaptersEnabled,
      removeFillerWords: prefs?.shouldRemoveFillerWords,
      personalizeToPodcast: prefs?.isPodcastPersonalizationEnabled
        ? defaultPodcastTitle
        : undefined,
    });

    try {
      setIsSubmitting(true);

      await onUpdateAIOptionsSubmit();
      onSubmit?.(prefs);
    } catch {
      notifyError();
    } finally {
      setIsSubmitting(false);
    }
  }, [
    defaultPodcastTitle,
    onSubmit,
    onUpdateAIOptionsSubmit,
    prefs,
    trackAiSelections,
  ]);

  return (
    <S.Container className={className}>
      <S.Content>
        <HeadySparkle width={151} height={118} />

        <Spacer orientation="vertical" align="center">
          <Heading level={1}>{title}</Heading>
          <S.Description>{description}</S.Description>
        </Spacer>

        <AIOptionsList
          {...{ allowPodcastSearch, isLoading }}
          {...aiOptionsListProps}
        />

        <Button
          fluid
          onPress={handleSubmit}
          disabled={isLoading || isSubmitting}
        >
          Continue with these settings
        </Button>
      </S.Content>
      <AIPodcastSearchModal />
    </S.Container>
  );
};

export default AIOptOut;
