import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';
import SuggestedClipPrimaryActionButton from './SuggestedClipPrimaryActionButton';
import SuggestedClipSecondaryActionButton from './SuggestedClipSecondaryActionButton';

export type SuggestedClipPopoverProps = {
  actions: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const SuggestedClipPopoverComponent: React.FC<SuggestedClipPopoverProps> = ({
  actions,
  className,
  style,
}) => (
  <S.PopoverRoot {...{ className, style }}>
    <Spacer space="small" align="center" justify="center">
      <S.IconContainer>
        <S.Gradient />
      </S.IconContainer>
      <S.Heading>Headliner AI</S.Heading>
    </Spacer>
    <Heading level={2}>
      We think this segment could be a good promo clip for your episode.
    </Heading>
    <S.Footer>{actions}</S.Footer>
  </S.PopoverRoot>
);

const SuggestedClipPopover = Object.assign(SuggestedClipPopoverComponent, {
  PrimaryActionButton: SuggestedClipPrimaryActionButton,
  SecondaryActionButton: SuggestedClipSecondaryActionButton,
});

export default SuggestedClipPopover;
