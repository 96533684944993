import { AxiosResponse } from 'axios';
import { parse } from 'content-disposition';
import { request } from 'api';
import { omitNil } from 'utils/objects';
import {
  EDDY_API_V1_AUDIO_EXPORT_URL,
  EDDY_API_V1_PROJECT_URL,
  EDDY_API_V1_SPEAKER_URL,
  EDDY_API_V1_TRANSCRIPT_URL,
  EDDY_API_V1_VIDEO_EXPORT_URL,
} from './constants';
import {
  CreateProjectArgs,
  CreateProjectResult,
  GetMyProjectsArgs,
  Project,
  ProjectListResult,
  ProjectTranscript,
  TranscriptUpdateRequest,
  UpdateProjectTranscriptTextArgs,
  UpdateProjectTranscriptSpeakerArgs,
  TranscriptSpeakerUpdateRequest,
  AudioExportArgs,
  AudioExport,
  ExportAudioArgs,
  ExportAudio,
  ExportAudioBodyArgs,
  ExportVideoArgs,
  ExportVideo,
  ExportVideoBodyArgs,
  VideoExport,
  VideoExportArgs,
  TranscriptEditSegmentSpeakerRequest,
  EditProjectTranscriptSegmentSpeakerArgs,
  UpdateProjectTranscriptChapterArgs,
  TranscriptChapterUpdateRequest,
  UpdateProjectSettingsArgs,
  ProjectNameSuggestionResult,
  ProjectNameSuggestionArgs,
  ClipSuggestionGroup,
  TranscriptShowNoteSuggestions,
  PromoPacksResult,
  PromoPackDownloadArgs,
  PromoPackDownloadResult,
  TranscriptCreateSegmentResponse,
  TranscriptCreateSegmentRequest,
  CreateSegmentArgs,
  DislikeClipSuggestionArgs,
  CreateChapterArgs,
  TranscriptCreateChapterResponse,
  TranscriptCreateChapterRequest,
  DeleteChapterArgs,
  SpeakersResult,
  SetProjectRelatedPodcastArgs,
  SetProjectRelatedPodcastRequest,
  PromoPackContentsArgs,
  PromoPackContentsResult,
  PromoPackSpecificAssetDownloadArgs,
  ProjectPublicAssetArgs,
  ProjectPublicAsset,
  RegenerateProjectAssetArgs,
  RegenerateProjectAssetResponse,
  RegenerateProjectAssetBodyArgs,
  ProjectAssetRegenerationStatusArgs,
  ProjectAssetRegeneration,
  ColorPaletteResult,
  GetColorPaletteArgs,
  UpdateColorPaletteArgs,
  EdgeAssetUpdateProps,
  UpdateProjectEdgeAssetResult,
  GetEdgeAssetStatusResult,
  GetEdgeAssetStatusArgs,
  EdgeAssetType,
  GetProjectTranscriptArgs,
} from './types';

export async function createProject({
  associatedMedias,
  name,
  traceId,
  isAutoChaptersEnabled,
}: CreateProjectArgs) {
  const { data } = await request.post<
    CreateProjectResult,
    AxiosResponse<CreateProjectResult>,
    CreateProjectArgs
  >(EDDY_API_V1_PROJECT_URL, {
    associatedMedias,
    name,
    traceId,
    isAutoChaptersEnabled,
  });

  return data;
}

export async function getMyProjects(args?: GetMyProjectsArgs) {
  const { data } = await request.get<
    ProjectListResult,
    AxiosResponse<ProjectListResult>,
    never
  >(EDDY_API_V1_PROJECT_URL, {
    params: args,
  });

  return data;
}

export async function deleteProject(cuid: string): Promise<void> {
  await request.delete(`${EDDY_API_V1_PROJECT_URL}/${cuid}`);
}

export async function getProject(cuid: string) {
  const { data } = await request.get<Project, AxiosResponse<Project>, never>(
    `${EDDY_API_V1_PROJECT_URL}/${cuid}`,
  );

  return data;
}

export async function getProjectTranscript(args: GetProjectTranscriptArgs) {
  const { transcriptId, startMillis, endMillis } = args;

  const { data } = await request.get<
    ProjectTranscript,
    AxiosResponse<ProjectTranscript>,
    never
  >(`${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}`, {
    params: {
      startMillis,
      endMillis,
    },
  });

  return data;
}

export async function updateProjectTranscriptText({
  transcriptId,
  edits,
  isFillerWordsRemoval,
}: UpdateProjectTranscriptTextArgs) {
  await request.put<void, AxiosResponse<void>, TranscriptUpdateRequest>(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/edit-text`,
    { edits, isFillerWordsRemoval },
  );

  return undefined;
}

export async function updateProjectSettings({
  isNameSuggestionApplied,
  isAutoChaptersEnabled,
  name,
  projectCuid,
}: UpdateProjectSettingsArgs): Promise<void> {
  await request.put(
    `${EDDY_API_V1_PROJECT_URL}/${projectCuid}`,
    omitNil({
      isAutoChaptersEnabled,
      isNameSuggestionApplied,
      name,
    }),
  );
}

export async function updateProjectTranscriptSpeaker({
  name,
  segmentId,
  speakerId,
  transcriptId,
}: UpdateProjectTranscriptSpeakerArgs) {
  await request.put<void, AxiosResponse<void>, TranscriptSpeakerUpdateRequest>(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/speaker/${speakerId}`,
    { name, segmentId },
  );

  return undefined;
}

export const regenerateProjectAsset = async (
  args: RegenerateProjectAssetArgs,
): Promise<RegenerateProjectAssetResponse> => {
  const {
    projectTranscriptId,
    assetType,
    lengthFeedback,
    formalityFeedback,
    toneFeedback,
    customFeedback,
    includeChapters,
    imageType,
    imageDimensionWidth,
    imageDimensionHeight,
  } = args;
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${projectTranscriptId}/asset-regeneration-job`;

  const { data } = await request.post<
    RegenerateProjectAssetResponse,
    AxiosResponse<RegenerateProjectAssetResponse>,
    RegenerateProjectAssetBodyArgs
  >(url, {
    assetType,
    lengthFeedback,
    formalityFeedback,
    toneFeedback,
    customFeedback,
    includeChapters,
    imageType,
    imageDimensionWidth,
    imageDimensionHeight,
  });

  return data;
};

export const getProjectAssetRegenerationStatus = async (
  args: ProjectAssetRegenerationStatusArgs,
): Promise<ProjectAssetRegeneration> => {
  const { projectTranscriptId, regenerationJobId } = args;
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${projectTranscriptId}/asset-regeneration-job/${regenerationJobId}`;

  const { data } = await request.get<
    ProjectAssetRegeneration,
    AxiosResponse<ProjectAssetRegeneration>
  >(url);

  return data;
};

export async function createChapter({
  transcriptId,
  newChapterStartWordId,
  newChapterTitle,
  newChapterUrl,
  newChapterImageUrl,
}: CreateChapterArgs): Promise<TranscriptCreateChapterResponse> {
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/chapter`;

  const { data } = await request.post<
    TranscriptCreateChapterResponse,
    AxiosResponse<TranscriptCreateChapterResponse>,
    TranscriptCreateChapterRequest
  >(url, {
    newChapterStartWordId,
    newChapterTitle,
    newChapterUrl,
    newChapterImageUrl,
  });

  return data;
}

export async function deleteChapter({
  chapterId,
  transcriptId,
}: DeleteChapterArgs): Promise<void> {
  await request.delete(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/chapter/${chapterId}`,
  );
}

export async function updateProjectTranscriptChapter({
  transcriptId,
  chapterId,
  title,
  url,
  imageUrl,
}: UpdateProjectTranscriptChapterArgs) {
  await request.put<void, AxiosResponse<void>, TranscriptChapterUpdateRequest>(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/chapter/${chapterId}`,
    { title, url, imageUrl },
  );

  return undefined;
}

export async function editProjectTranscriptSegmentSpeaker({
  edits,
  transcriptId,
}: EditProjectTranscriptSegmentSpeakerArgs) {
  await request.put<
    void,
    AxiosResponse<void>,
    TranscriptEditSegmentSpeakerRequest
  >(`${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/edit-segment-speaker`, {
    edits,
  });

  return undefined;
}

export const exportAudio = async (
  args: ExportAudioArgs,
): Promise<ExportAudio> => {
  const { projectCuid, selection = {} } = args;
  const url = `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/audio-export`;

  const { data } = await request.post<
    ExportAudio,
    AxiosResponse<ExportAudio>,
    ExportAudioBodyArgs
  >(url, selection);

  return data;
};

export const getAudioExport = async (
  args: AudioExportArgs,
): Promise<AudioExport> => {
  const { audioExportId } = args;
  const url = `${EDDY_API_V1_AUDIO_EXPORT_URL}/${audioExportId}`;

  const { data } = await request.get<AudioExport, AxiosResponse<AudioExport>>(
    url,
  );

  return data;
};

export const exportVideo = async (
  args: ExportVideoArgs,
): Promise<ExportVideo> => {
  const { projectCuid, selection = {} } = args;
  const url = `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/video-export`;

  const { data } = await request.post<
    ExportVideo,
    AxiosResponse<ExportVideo>,
    ExportVideoBodyArgs
  >(url, selection);

  return data;
};

export const getVideoExport = async (
  args: VideoExportArgs,
): Promise<VideoExport> => {
  const { videoExportId } = args;
  const url = `${EDDY_API_V1_VIDEO_EXPORT_URL}/${videoExportId}`;

  const { data } = await request.get<VideoExport, AxiosResponse<VideoExport>>(
    url,
  );

  return data;
};

export const getProjectNameSuggestion = async (
  args: ProjectNameSuggestionArgs,
): Promise<ProjectNameSuggestionResult> => {
  const { projectCuid } = args;
  const url = `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/name-suggestion`;

  const { data } = await request.get<ProjectNameSuggestionResult>(url);

  return data;
};

export async function getClipSuggestions(
  transcriptId: number,
): Promise<ClipSuggestionGroup> {
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/clip-suggestions`;

  const { data } = await request.get<ClipSuggestionGroup>(url);

  return data;
}

export async function getTranscriptShowNoteSuggestions(
  transcriptId: number,
): Promise<TranscriptShowNoteSuggestions> {
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/show-notes-suggestions`;

  const { data } = await request.get<TranscriptShowNoteSuggestions>(url);

  return data;
}

export async function createSegment({
  transcriptId,
  newSegmentStartWordId,
  newSegmentSpeakerId,
}: CreateSegmentArgs): Promise<TranscriptCreateSegmentResponse> {
  const url = `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/segment`;

  const { data } = await request.post<
    TranscriptCreateSegmentResponse,
    AxiosResponse<TranscriptCreateSegmentResponse>,
    TranscriptCreateSegmentRequest
  >(url, {
    newSegmentSpeakerId,
    newSegmentStartWordId,
  });

  return data;
}

export async function deleteSegment(
  transcriptId: number,
  segmentId: number,
): Promise<void> {
  await request.delete(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/segment/${segmentId}`,
  );
}

export async function getPromoPacks(transcriptId: number) {
  const { data } = await request.get<
    PromoPacksResult,
    AxiosResponse<PromoPacksResult>,
    never
  >(`${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/promo-packs`);

  return data;
}

export const getPromoPackDownload = async (
  args: PromoPackDownloadArgs,
): Promise<PromoPackDownloadResult> => {
  const { transcriptId, promoPackType } = args;

  const { data, headers } = await request.get<Blob>(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/promo-pack/${promoPackType}/download`,
    {
      responseType: 'blob',
    },
  );

  const { parameters } = parse(headers['content-disposition']);

  return {
    file: data,
    fileName: parameters?.filename,
  };
};

export const downloadPromoPackAsset = async (
  args: PromoPackSpecificAssetDownloadArgs,
): Promise<PromoPackDownloadResult> => {
  const { transcriptId, promoPackType, assetType } = args;

  const { data, headers } = await request.get<Blob>(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/promo-pack/${promoPackType}/${assetType}/download`,
    {
      responseType: 'blob',
    },
  );

  const { parameters } = parse(headers['content-disposition']);

  return {
    file: data,
    fileName: parameters?.filename,
  };
};

export const getPromoPackContents = async (
  args: PromoPackContentsArgs,
): Promise<PromoPackContentsResult> => {
  const { transcriptId, promoPackType } = args;

  const { data } = await request.get<
    PromoPackContentsResult,
    AxiosResponse<PromoPackContentsResult>,
    never
  >(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${transcriptId}/promo-pack/${promoPackType}`,
  );

  return data;
};

export async function dislikeSuggestedClip({
  projectTranscriptId,
  suggestionId,
  feedbackType,
}: DislikeClipSuggestionArgs): Promise<void> {
  await request.post(
    `${EDDY_API_V1_TRANSCRIPT_URL}/${projectTranscriptId}/clip-suggestions/${suggestionId}/feedback`,
    {
      feedbackType,
    },
  );
}

export async function getSpeakers() {
  const { data } = await request.get<
    SpeakersResult,
    AxiosResponse<SpeakersResult>,
    never
  >(EDDY_API_V1_SPEAKER_URL);

  return data;
}

export async function setProjectRelatedPodcast({
  projectId,
  podcastId,
}: SetProjectRelatedPodcastArgs): Promise<void> {
  await request.post<void, Promise<void>, SetProjectRelatedPodcastRequest>(
    `${EDDY_API_V1_PROJECT_URL}/${projectId}/related-podcast`,
    {
      podcastId,
    },
  );
}
export async function updateEdgeAsset(
  props: EdgeAssetUpdateProps,
): Promise<UpdateProjectEdgeAssetResult> {
  const { projectCuid, edgeAssetType, ...rest } = props;

  const { data } = await request.put(
    `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/${edgeAssetType}`,
    rest,
  );

  return data;
}

export async function getEdgeAssetStatus({
  edgeAssetId,
  projectCuid,
}: GetEdgeAssetStatusArgs) {
  const { data } = await request.get<GetEdgeAssetStatusResult>(
    `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/edge-asset/${edgeAssetId}`,
  );

  return data;
}

export async function deleteEdgeAsset(
  cuid: string,
  edgeAssetType: EdgeAssetType,
): Promise<void> {
  await request.delete(`${EDDY_API_V1_PROJECT_URL}/${cuid}/${edgeAssetType}`);
}
export const getProjectPublicAsset = async (
  args: ProjectPublicAssetArgs,
): Promise<ProjectPublicAsset> => {
  const { projectCuid } = args;

  const { data } = await request.get<
    ProjectPublicAsset,
    AxiosResponse<ProjectPublicAsset>
  >(`${EDDY_API_V1_PROJECT_URL}/${projectCuid}/public-asset`);

  return data;
};

export const getColorPalette = async ({
  projectCuid,
}: GetColorPaletteArgs): Promise<ColorPaletteResult> => {
  const { data } = await request.get<
    ColorPaletteResult,
    AxiosResponse<ColorPaletteResult>
  >(`${EDDY_API_V1_PROJECT_URL}/${projectCuid}/color-palette`);

  return data;
};

export async function updateColorPalette({
  projectCuid,
  colors,
}: UpdateColorPaletteArgs) {
  await request.put<void, AxiosResponse<void>>(
    `${EDDY_API_V1_PROJECT_URL}/${projectCuid}/color-palette`,
    { colors },
  );

  return undefined;
}
