import { ControlledFeature } from '../features';

export interface ModalsProps {}

const Modals: React.FC<ModalsProps> = () => (
  <>
    <ControlledFeature feature="editing.deletionAdjustment.modal" />
    <ControlledFeature feature="edgeAssets.modal" />
    <ControlledFeature feature="promoPack.modal" />
    <ControlledFeature feature="export.modal" />
    <ControlledFeature feature="chapters.modal" />
  </>
);

export default Modals;
