import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import AppHeader from 'components/AppHeader';
import { ControlledFeature } from '../features';
import { HeaderProvider } from './HeaderContext';
import * as S from './styles';

export interface HeaderProps {
  className?: string;
  exportButtonIsDisabled?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  className,
  exportButtonIsDisabled,
}) => (
  <HeaderProvider canExport={!exportButtonIsDisabled}>
    <S.Header className={className}>
      <ControlledFeature feature="navbar.logo" />
      <AppHeader.Item align="center" as={Spacer} pull="right" space={2}>
        <ControlledFeature feature="support.supportButton" />
        <ControlledFeature feature="export.exportButton" />
      </AppHeader.Item>
    </S.Header>
  </HeaderProvider>
);

export default Header;
