import { UpsellModalContext } from 'state/modal/modals';
import AutomatedTranscriptionsContents from './AutomatedTranscriptionsContents';
import DefaultContents from './DefaultContents';
import IntroAndOutroContents from './IntroAndOutroContents';
import MonthlyUploadLimitsErrorContents from './MonthlyUploadLimitsErrorContents';
import { BaseUpsellModalProps } from './types';
import UploadLimitsContents from './UploadLimitsContents';
import UploadLimitsErrorContents from './UploadLimitsErrorContents';

export const UPSELL_MODAL_CONTEXTS: Record<
  UpsellModalContext,
  React.FC<BaseUpsellModalProps>
> = {
  default: DefaultContents,
  monthlyUploadLimitsError: MonthlyUploadLimitsErrorContents,
  uploadLimits: UploadLimitsContents,
  uploadLimitsError: UploadLimitsErrorContents,
  introOutro: IntroAndOutroContents,
  automatedTranscriptions: AutomatedTranscriptionsContents,
};
