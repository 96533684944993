import styled from 'styled-components';
import Article from 'components/Article';
import BaseFadingHeader from 'components/FadingHeader';
import { TRANSCRIPT_CONTAINER_WIDTH } from '../constants';
import BaseTranscript from '../Transcript';

export const Container = styled.div`
  padding-bottom: ${({ theme }) => theme.footerFadeHeight}px;
`;

export const FadingHeader = styled(BaseFadingHeader)`
  z-index: 1;
`;

export const TranscriptContainer = styled(Article).attrs({
  width: TRANSCRIPT_CONTAINER_WIDTH,
})`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const VideoContainer = styled.div`
  max-width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const Transcript = styled(BaseTranscript)`
  flex-grow: 1;
`;
