import React from 'react';
import { usePlanMonthlyDurationLimitHours } from 'state/pricing';
import { formatHoursString } from 'utils/time';
import UploadLimitsErrorContents from '../UploadLimitsErrorContents';

export interface MonthlyUploadLimitsErrorContentsProps {}

const MonthlyUploadLimitsErrorContents: React.FC<
  MonthlyUploadLimitsErrorContentsProps
> = () => {
  const { data: monthlyDurationLimitHours } =
    usePlanMonthlyDurationLimitHours();

  const durationStr =
    monthlyDurationLimitHours === undefined
      ? undefined
      : formatHoursString(monthlyDurationLimitHours);

  return (
    <UploadLimitsErrorContents
      title={`This upload exceeds your ${durationStr} of free transcription this month`}
    />
  );
};

export default MonthlyUploadLimitsErrorContents;
