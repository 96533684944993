import { getThemeColor } from '@sparemin/blockhead';
import { transparentize } from 'color2k';
import styled, { css } from 'styled-components';
import {
  ACTIVE_SUGGESTION_CLASSNAME,
  HIDE_DELETED_WORDS_CLASSNAME,
} from '../constants';
import { selectableTranscriptText } from '../styles';
import { WORD_FONT_SIZE_PX, WORD_LINE_HEIGHT } from './constants';
import { WordStyleProps } from './types';

const deletedStyle = css`
  text-decoration: line-through;
  color: ${getThemeColor('actionTertiary')};

  .${HIDE_DELETED_WORDS_CLASSNAME} && {
    display: none;
  }
`;

const baseActiveStyle = css`
  background-color: ${getThemeColor('actionPrimary')};
  border-radius: 8px;
  content: '';
  position: absolute;
  z-index: -1;
  inset: 3px 1px 3px -2px;
`;

const activeStyle = css`
  color: ${getThemeColor('white')};

  &::after {
    ${baseActiveStyle};
  }
`;

const selectedStyle = css`
  &::after {
    background-color: ${({ theme }) =>
      transparentize(theme.palette.actionPrimary.main, 0.75)};
    bottom: 0;
    border-radius: 0;
    content: '';
    inset: 0px;
    position: absolute;
  }
`;

/* 
  Apply the combined active and selected styles.
  Both active and selected styles were applying styles to &::after, which caused 
  some styles to be overridden and not work correctly. By splitting the styling 
  between &::before and &::after, we ensure that both active and selected styles 
  are fully applied without conflicts. 
*/
const activeAndSelectedStyle = css`
  &::before {
    ${baseActiveStyle};
  }

  ${selectedStyle}
`;

const suggestedClipStyle = css`
  .${ACTIVE_SUGGESTION_CLASSNAME} & {
    &::before {
      background-color: ${({ theme }) =>
        transparentize(theme.palette.actionSecondary.main, 0.75)};
      border-bottom: 1px solid ${getThemeColor('actionSecondary')};
      border-radius: 0;
      content: '';
      inset: 0px;
      position: absolute;
      z-index: -1;
    }
  }
`;

const isHoveredStyle = css`
  background-color: ${getThemeColor('l3')};
  border-radius: 8px;
`;

export const Root = styled.span<WordStyleProps>`
  ${({ theme }) => theme.typography.variants.fabLabel.default};
  cursor: pointer;
  display: inline-block;
  font-size: ${WORD_FONT_SIZE_PX}px;
  line-height: ${WORD_LINE_HEIGHT};
  position: relative;
  text-transform: none;
  user-select: auto !important;
  z-index: 1;
  pointer-events: auto;

  ${selectableTranscriptText}
  ${suggestedClipStyle}

  &:active:not([disabled]) {
    box-shadow: none;
  }

  ${({ $isDeleted }) => $isDeleted && deletedStyle}
  ${({ $isActive }) => $isActive && activeStyle}
  ${({ $isSelected }) => $isSelected && selectedStyle}
  ${({ $isActive, $isSelected }) =>
    $isActive && $isSelected && activeAndSelectedStyle}
  ${({ $isHovered }) => $isHovered && isHoveredStyle}
`;
