import { PromoPackAssetType } from 'api';
import { isOptInAsset } from '../PromoPackModal/utils';
import { usePromoPackAsset } from './useGetPromoPackContents';

function useIsOptInAsset(assetType: PromoPackAssetType | undefined) {
  const { data: asset } = usePromoPackAsset(assetType);
  return isOptInAsset(assetType) && asset?.status === 'notFound';
}

export default useIsOptInAsset;
