import React, { memo } from 'react';
import { ControlledFeature } from 'pages/TranscriptEditorPage/features';
import {
  useSegmentChapters,
  useSegmentInitialWordIndex,
} from 'pages/TranscriptEditorPage/state';
import SpeakerBadge from '../SpeakerBadge';
import Word from '../Word';
import { ChapterProvider } from './ChapterContext';
import * as S from './styles';
import useSegmentSuggestions from './useSegmentSuggestions';
import { highlightSuggestions } from './utils';

export interface SpeakerSegmentProps {
  segmentId: number;
}

const SegmentChapters: React.FC<SpeakerSegmentProps> = memo(({ segmentId }) => {
  const { data: segmentChapters } = useSegmentChapters(segmentId);
  const { data: segmentWordOffset = 0 } = useSegmentInitialWordIndex(segmentId);
  const suggestions = useSegmentSuggestions(segmentId);

  // transcript words are grouped together in segments using an array.  suggestions
  // use word indices relating their position in the transcript overall.  The segmentWordOffset
  // is the index of the first word in the segment as it relates to the overall
  // transcript. When subtracting this index from a suggestion index, you get the
  // "local" index of the word in the segment words array.  For example, if the first
  // word in the segment starts at transcript-index 1000 and the first word in the
  // suggestion starts at transcript-index 1010, then in terms of words in the segment,
  // the first word in the suggestion can be found at suggestion-index 1010 - 1000 = 10.
  //
  // chapters complicate this because the "local" segment word array is broken
  // down further to group by chapters. Using the previous example, if this segment
  // had 2 chapter fragments with 20 words each, there would be two chapter arrays
  // spanning indices [0, 20]. When calculating that the suggestion starts at index 10,
  // we now have to account for the fact that there are 2 word arrays with an index 10.
  //
  // the appropriate adjustment is made by incrementally adding to the segmentWordOffset
  // the length of all word arrays that came before it.  So, the second chapter fragment
  // spanning indices [0, 20] can actually be seen as spanning indices [20, 40] after
  // accounting for the chapter fragment that came before it.
  let segmentChapterWordOffset = segmentWordOffset;

  return (
    <>
      {segmentChapters?.map(
        ({ chapterId, hasTitle, words }, chapterIndex, chapters) => {
          segmentChapterWordOffset +=
            chapters[chapterIndex - 1]?.words.length ?? 0;

          return (
            !!words.length && (
              <React.Fragment key={chapterId ?? words[0]}>
                {hasTitle && chapterId && (
                  <ChapterProvider chapterId={chapterId}>
                    <ControlledFeature feature="chapters.title" />
                  </ChapterProvider>
                )}

                {chapterIndex === 0 && <SpeakerBadge segmentId={segmentId} />}

                <S.Paragraph>
                  {highlightSuggestions(
                    words.map((id, i, ary) => (
                      <Word id={id} key={id} separator={i < ary.length - 1} />
                    )),
                    segmentChapterWordOffset,
                    suggestions,
                  )}
                </S.Paragraph>
              </React.Fragment>
            )
          );
        },
      )}
    </>
  );
});

export default SegmentChapters;
