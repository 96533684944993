import { Download, Tooltip } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import useToggle from 'hooks/useToggle';
import { useProjectCuid } from 'pages/TranscriptEditorPage/state';
import { useModalsActions } from 'state/modal';
import {
  SuggestedClipDislikePopover,
  useClipSuggestionWords,
  useSuggestionPopoverTracking,
  useWordsInSuggestion,
  SuggestedClipPopover,
} from '../../../Transcript';
import { useNativeTranscriptEditorPageContext } from '../NativeTranscriptEditorPageContext';
import * as S from './styles';

export interface NativeActionsProps {}

const NativeActions: React.FC<NativeActionsProps> = () => {
  const { pushModal } = useModalsActions();

  const { data: projectCuid } = useProjectCuid();
  const words = useWordsInSuggestion();
  const [startWord, endWord] = useClipSuggestionWords();

  const { onCreateClip, onDownloadClip } = useSuggestionPopoverTracking();
  const { downloadClip, downloadClipStatus } =
    useNativeTranscriptEditorPageContext();

  const [dislikeButton, setDislikeButton] = useState<HTMLButtonElement | null>(
    null,
  );
  const { toggle: toggleDislikePopper, value: isDislikePopperOpen } =
    useToggle(false);

  const isDownloadingClip = downloadClipStatus === 'loading';

  const handleCreateClip = useCallback(() => {
    if (words && projectCuid) {
      onCreateClip();
      pushModal({
        name: 'AudiogramExport',
        params: {
          words,
        },
      });
    }
  }, [onCreateClip, projectCuid, pushModal, words]);

  const handleDownloadClip = useCallback(() => {
    onDownloadClip();
    downloadClip(startWord.id, endWord.id);
  }, [downloadClip, endWord.id, onDownloadClip, startWord.id]);

  const handleDislike = useCallback(() => {
    toggleDislikePopper();
  }, [toggleDislikePopper]);

  return (
    <>
      <Tooltip
        contents="Clip download will be ready soon"
        isDisabled={!isDownloadingClip}
      >
        <SuggestedClipPopover.SecondaryActionButton
          aria-label="download clip"
          onPress={handleDownloadClip}
          isDisabled={isDownloadingClip}
        >
          <Download />
        </SuggestedClipPopover.SecondaryActionButton>
      </Tooltip>
      <SuggestedClipPopover.PrimaryActionButton onPress={handleCreateClip} />

      <SuggestedClipPopover.SecondaryActionButton
        aria-label="dislike clip"
        ref={setDislikeButton}
        onPress={handleDislike}
      >
        <S.DislikeIcon />
      </SuggestedClipPopover.SecondaryActionButton>
      <SuggestedClipDislikePopover
        isOpen={isDislikePopperOpen}
        triggerElement={dislikeButton}
      />
    </>
  );
};

export default NativeActions;
