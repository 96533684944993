import {
  Button,
  Container,
  getThemeColor,
  Spacer,
  Tooltip,
  Heading as BlockheadHeading,
  GradientIcon,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const PopoverRoot = styled(Container).attrs({
  cornerRadius: 2,
  forwardedAs: Spacer,
  gutter: 3,
  orientation: 'vertical',
  space: 'small',
})`
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  color: ${getThemeColor('d1')};
  width: 282px;
`;

export const Footer = styled(Spacer).attrs({
  align: 'center',
  justify: 'space-between',
  space: '12px',
})`
  width: 100%;
  padding-top: 12px;
`;

export const SecondaryButtonTooltip = styled(Tooltip)`
  flex: 0 0 30px;
`;

export const SecondaryButton = styled(Button)`
  border-radius: 50%;
  height: 32px;
  padding: 0;
  width: 32px;
`;

export const Heading = styled(BlockheadHeading).attrs({
  level: 2,
})`
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

export const IconContainer = styled.span`
  height: 32px;
  width: 32px;
`;

export const Gradient = styled(GradientIcon)`
  height: 100%;
  width: 100%;
`;
