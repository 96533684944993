import { PODCAST_SERVICE_URL } from 'config';

export const PODCAST_SERVICE_V1_URL = `${PODCAST_SERVICE_URL}/api/v1/podcast`;

export const PODCAST_SERVICE_V1_PODCASTS_URL = `${PODCAST_SERVICE_V1_URL}/podcasts`;

export const PODCAST_SERVICE_V1_FAVORITES_URL = `${PODCAST_SERVICE_V1_PODCASTS_URL}/favorites`;

export const PODCAST_SERVICE_V1_AUTO_CREATE_URL = `${PODCAST_SERVICE_V1_URL}/auto-create`;

export const PODCAST_SERVICE_V1_TRANSCRIPT_SUBSCRIPTION_URL = `${PODCAST_SERVICE_V1_AUTO_CREATE_URL}/transcript-subscription`;
