import { useCallback } from 'react';
import { useMutation } from 'react-query';

import {
  getTranscriptDownload,
  TranscriptFormat,
} from 'api/services/transcript';
import notifyError from 'components/notification';
import { AudioSelection } from 'types';
import { downloadBlob } from 'utils/download';

interface BaseExportTranscriptArgs {
  format: TranscriptFormat;
  projectCuid: string;
  selection?: AudioSelection;
  includeSpeakerLabels?: boolean;
  shouldFormatForYoutube?: boolean;
  includeStaticTimeMarkers?: boolean;
  includeEdgeAssetShift?: boolean;
}

interface ExportOnlyTranscriptArgs extends BaseExportTranscriptArgs {
  download?: false;
}

interface ExportAndDownloadTranscriptArgs extends BaseExportTranscriptArgs {
  download: true;
  projectName: string;
}

type ExportTranscriptArgs =
  | ExportOnlyTranscriptArgs
  | ExportAndDownloadTranscriptArgs;

function onError(format: TranscriptFormat) {
  notifyError({
    heading: 'Error exporting transcript',
    errorCode: { code: 'ER005', type: format },
  });
}

async function onSuccess(transcriptBlob: Blob, args: ExportTranscriptArgs) {
  if (args.download) {
    const { format, projectName } = args;
    const filename = `${projectName}.${format}`;
    downloadBlob(filename, transcriptBlob);
  }
}

export default function useExportTranscript() {
  const { status, mutate, mutateAsync } = useMutation(getTranscriptDownload);

  const exportTranscript = useCallback(
    (args: ExportTranscriptArgs) => {
      const {
        format,
        projectCuid,
        selection,
        includeSpeakerLabels,
        shouldFormatForYoutube,
        includeStaticTimeMarkers,
        includeEdgeAssetShift,
      } = args;
      mutate(
        {
          format,
          projectCuid,
          selection,
          includeSpeakerLabels,
          shouldFormatForYoutube,
          includeStaticTimeMarkers,
          includeEdgeAssetShift,
        },
        {
          onError() {
            onError(format);
          },
          onSuccess(transcriptBlob) {
            onSuccess(transcriptBlob, args);
          },
        },
      );
    },
    [mutate],
  );

  const exportTranscriptAsync = useCallback(
    async (args: ExportTranscriptArgs) => {
      const { format, projectCuid, selection } = args;
      try {
        const transcriptBlob = await mutateAsync({
          format,
          projectCuid,
          selection,
        });
        onSuccess(transcriptBlob, args);
      } catch (err) {
        onError(format);
        throw err;
      }
    },
    [mutateAsync],
  );

  return {
    exportTranscript,
    exportTranscriptAsync,
    status,
    isIdle: status === 'idle',
    isLoading: status === 'loading',
  };
}
