/**
 * Adding async imports into the router.tsx file breaks HMR.  eslint will report
 * the error and the vite terminal output will complain about HMR not working.
 *
 * The easiest way to fix this is to put all of the lazy routes into a single file
 * and then import that file into the router.
 *
 * More info here https://github.com/ArnaudBarre/eslint-plugin-react-refresh/issues/25#issuecomment-2041616581
 */

import { lazy } from 'react';

const AuthenticationPage = lazy(() => import('pages/AuthenticationPage'));
const CreateProjectPage = lazy(() => import('pages/CreateProjectPage'));
const ProjectsPage = lazy(() => import('pages/ProjectsPage'));

const NativeTranscriptEditorPage = lazy(() =>
  import('pages/TranscriptEditorPage').then((module) => ({
    default: module.NativeTranscriptEditorPage,
  })),
);

const QueryStringAuthRoute = lazy(() =>
  import('components/QueryStringAuthenticator').then((module) => ({
    default: module.QueryStringAuthRoute,
  })),
);

const TranscriptOwnerRoute = lazy(
  () => import('components/TranscriptOwnerRoute'),
);

export {
  AuthenticationPage,
  CreateProjectPage,
  NativeTranscriptEditorPage,
  ProjectsPage,
  QueryStringAuthRoute,
  TranscriptOwnerRoute,
};
