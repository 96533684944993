import { getElementsInRange, selectElements } from 'utils/selection';
import { WordDatasetKey } from '../Transcript/Word';
import { WORD_ID_DATA_ATTRIBUTE } from '../Transcript/Word/constants';

export function isWordElement(el: HTMLElement | null) {
  return !!el && !!el.dataset[WordDatasetKey.ID];
}

export function getWordIdFromElement(el: HTMLElement) {
  return Number(el.dataset[WordDatasetKey.ID]);
}

export function getWordElementsInRange(range: Range | undefined) {
  return getElementsInRange(range, isWordElement);
}

export function getWordIdsInRange(range: Range | undefined) {
  return getWordElementsInRange(range).map(getWordIdFromElement);
}

export function getWordElement(wordId: number) {
  return document.querySelector(`[${WORD_ID_DATA_ATTRIBUTE}='${wordId}']`);
}

export function selectFullWordsInRange(wordIds: number[]) {
  const firstId = wordIds[0];
  const lastId = wordIds[wordIds.length - 1];

  const firstWordEl = getWordElement(firstId);
  const lastWordEl = getWordElement(lastId);

  return selectElements(firstWordEl, lastWordEl);
}

export function isWordInDom(wordId: number) {
  return getWordElement(wordId) !== null;
}
