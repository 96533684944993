import { Download, Tooltip } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import {
  useProjectCuid,
  useProjectMediaType,
  useProjectName,
} from '../../../state';
import { useWordsContextMenuConfig } from '../../../state/contextMenu';
import * as S from './styles';

export interface DownloadClipContextMenuButtonProps {}

const DownloadClipContextMenuButton: React.FC<
  DownloadClipContextMenuButtonProps
> = () => {
  const config = useWordsContextMenuConfig();
  const { data: projectCuid } = useProjectCuid();
  const { data: projectName } = useProjectName();
  const { data: projectType } = useProjectMediaType();
  const { pushModal } = useModalsActions();
  const { trackHighlightPopperClicked } = useEventTracking();

  const handleDownload = useCallback(() => {
    if (!projectCuid || !projectName || !projectType || !config) {
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'Download' });
    const { words } = config;

    pushModal({
      name: 'ShareAudio',
      params: {
        words,
        projectType,
      },
    });
  }, [
    config,
    projectCuid,
    projectName,
    pushModal,
    trackHighlightPopperClicked,
    projectType,
  ]);

  return (
    <Tooltip contents="Download clip">
      <S.Button
        aria-label="download clip"
        onPress={handleDownload}
        variant="solid"
        color="tertiary"
      >
        <Download />
      </S.Button>
    </Tooltip>
  );
};

export default DownloadClipContextMenuButton;
