import { lazy } from 'react';

const DefaultEmbedTranscriptEditorPage = lazy(
  () => import('./DefaultEmbedTranscriptEditorPage'),
);

const InlineEmbedTranscriptEditorPage = lazy(
  () => import('./InlineEmbedTranscriptEditorPage'),
);

export { DefaultEmbedTranscriptEditorPage, InlineEmbedTranscriptEditorPage };
