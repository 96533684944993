import { useHover, useMergedRefs, usePopover } from '@sparemin/blockhead';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { ControlledFeature } from 'pages/TranscriptEditorPage/features';
import {
  SUGGESTED_CLIP_TOOLTIP_CLASSNAME,
  SUGGESTED_CLIP_TOOLTIP_TRANSITION_DURATION_MILLIS,
} from './constants';
import * as S from './styles';

export type SuggestedClipTooltipContentsProps = {
  isOpen: boolean;
  onHoverEnd?: () => void;
  onHoverStart?: () => void;
};

const SuggestedClipTooltipContents: React.FC<
  SuggestedClipTooltipContentsProps
> = ({ isOpen, onHoverEnd, onHoverStart }) => {
  const transitionRef = useRef<HTMLDivElement>(null);
  const { popoverAttributes, popoverRef, popoverStyle } = usePopover();

  const { hoverProps } = useHover({ onHoverStart, onHoverEnd });
  const mergedRef = useMergedRefs([transitionRef, popoverRef]);

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      nodeRef={transitionRef}
      timeout={SUGGESTED_CLIP_TOOLTIP_TRANSITION_DURATION_MILLIS}
      unmountOnExit
    >
      {(status) =>
        createPortal(
          <S.Popover
            {...popoverAttributes}
            {...hoverProps}
            className={SUGGESTED_CLIP_TOOLTIP_CLASSNAME}
            ref={mergedRef}
            status={status}
            style={popoverStyle}
            role="tooltip"
          >
            <ControlledFeature feature="suggestedClips.popper" />
          </S.Popover>,
          document.body,
        )
      }
    </Transition>
  );
};

export default SuggestedClipTooltipContents;
