import cn from 'classnames';
import { Suspense } from 'react';
import { ControlledFeature } from '../features';
import { useShowDeletedWords } from '../state/editorUi';
import { HIDE_DELETED_WORDS_CLASSNAME } from './constants';
import Segments from './Segments';
import * as S from './styles';
import Title from './Title';

export interface TranscriptProps {
  className?: string;
}

const Transcript: React.FC<TranscriptProps> = ({ className }) => {
  const showDeletedWords = useShowDeletedWords();

  // we don't want every segment and therefore every word re-rendering because
  // showDeletedWords has changed, so segments are rendered in a dedicated
  // memoized component
  return (
    <Suspense fallback={null}>
      <S.Container
        className={cn(className, {
          [HIDE_DELETED_WORDS_CLASSNAME]: !showDeletedWords,
        })}
      >
        <Title />

        <ControlledFeature feature="edgeAssets.introUploader" />

        <Segments />

        <ControlledFeature feature="edgeAssets.outroUploader" />
      </S.Container>
    </Suspense>
  );
};

export default Transcript;
