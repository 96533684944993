import { useContext } from 'react';
import { FeatureConfigPath } from '../types';
import { FeatureContext } from './FeatureContext';
import { featureSelector } from './selectors';

function useFeature() {
  const context = useContext(FeatureContext);

  if (context === undefined) {
    throw new Error('useFeature must be used within FeatureProvider');
  }

  return context;
}

export const useFeatureConfig = <Path extends FeatureConfigPath>(path: Path) =>
  featureSelector(useFeature(), path);
