import { getThemeColor, IconButton } from '@sparemin/blockhead';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled(IconButton).attrs({
  variant: 'solid',
})`
  background-color: transparent;
  color: ${getThemeColor('d1')};
  height: 48px;
  width: 48px;

  &[data-hovered='true']:not([disabled]) {
    background-color: transparent;
    opacity: 0.5;
  }
`;
