import api from '@sparemin/api-sdk';
import { UserAuthResult } from '@sparemin/auth';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getUserProfile } from 'api/services/user';
import notifyError from 'components/notification';
import { useAuth } from 'state/auth';
import { useEventTracking } from 'state/eventTracking';
import { subscriptionQueryKeys } from 'state/pricing';
import { planNameSelector } from 'state/pricing/selectors';
import { userQueryKeys } from 'state/user';

export default function useAuthSuccess() {
  const { login } = useAuth();
  const queryClient = useQueryClient();
  const { setUserTrackingInfo, trackUserLogin, trackUserSignup } =
    useEventTracking();

  const onAuthSuccess = useCallback(
    async (result: UserAuthResult) => {
      const { spareminToken, userId } = result;
      try {
        const profile = await getUserProfile({
          userId,
          token: spareminToken,
        });
        const planData = await api.getMySubscription(undefined, {
          accessToken: spareminToken,
        });
        const planName = planNameSelector(planData);
        queryClient.setQueryData(userQueryKeys.profile(userId), profile);
        queryClient.setQueryData(
          subscriptionQueryKeys.mySubscription(userId),
          planData,
        );
        if (profile && profile.email && planName) {
          setUserTrackingInfo(profile.email, userId, planName);
          if (result.eventType === 'login') {
            trackUserLogin(profile.email);
          } else {
            trackUserSignup(profile.email);
          }
          login({
            accessToken: result.spareminToken,
            userId: result.userId,
          });
        }
      } catch (err) {
        notifyError({ heading: 'Something went wrong. Please try again.' });
      }
    },
    [login, queryClient, setUserTrackingInfo, trackUserLogin, trackUserSignup],
  );

  return { onAuthSuccess };
}
