import { userQueryKey } from 'state/queryKeyUtils';

export const podcastQueryKeys = {
  all: [{ scope: 'podcast' }] as const,
  myFavoritePodcasts: (userId?: number) =>
    [
      {
        ...podcastQueryKeys.all[0],
        entity: 'myFavoritePodcasts',
        ...userQueryKey(userId),
      },
    ] as const,
  myTranscriptSubscriptions: (userId?: number) =>
    [
      {
        ...podcastQueryKeys.all[0],
        entity: 'myTranscriptSubscriptions',
        ...userQueryKey(userId),
      },
    ] as const,
};
