import { PopoverContainerProps } from '@sparemin/blockhead';
import React from 'react';
import Popover from 'components/Popover';
import { useRegisterKeybinding } from 'state/hotkey';
import { clearSelection } from 'utils/selection';
import {
  isWordsContextMenuConfig,
  useContextMenuActions,
  useContextMenuConfig,
} from '../state/contextMenu';
import SpeakerContextMenu from './SpeakerContentextMenu';
import WordContextMenu from './WordContextMenu';

export interface EditorContextMenuContentsProps
  extends Pick<PopoverContainerProps, 'triggerElement'> {}
/**
 * This contents component was created primarly so that the Escape keybindings
 * don't get added unless the context menu is open.  Doing this allows the Escape
 * keybindings to take priority over other Escape keybindings, which is typically
 * what we want since the context menu renders on top of almost everything else
 * (except modals, which should close the context menu before opening).
 */
const EditorContextMenuContents: React.FC<EditorContextMenuContentsProps> = ({
  triggerElement,
}) => {
  const config = useContextMenuConfig();
  const { closeContextMenu } = useContextMenuActions();

  const key = JSON.stringify(config);
  const isWordMenu = isWordsContextMenuConfig(config);

  useRegisterKeybinding('Escape', () => {
    closeContextMenu();
    clearSelection();
  });

  return (
    <Popover
      triggerElement={triggerElement}
      offset={8}
      isOpen
      placement={isWordMenu ? 'top' : 'bottom'}
      preventOverflow
    >
      {isWordMenu ? (
        <WordContextMenu key={`word-${key}`} />
      ) : (
        <SpeakerContextMenu key={`speaker-${key}`} />
      )}
    </Popover>
  );
};

export default EditorContextMenuContents;
