import api, { CheckoutUrlPlanName } from '@sparemin/api-sdk';
import { ClosePopup, OnOpenPopup, usePopup } from '@sparemin/blockhead';
import { useCallback, useRef, useState } from 'react';
import { QueryStatus } from 'react-query';
import { APPLICATION_NAME } from 'config';
import { useAuth } from 'state/auth';
import { usePollForNewTier } from 'state/pricing';
import { AnchorProps } from './types';
import useCheckoutPostMessage from './useCheckoutPostMessage';

export interface UseCheckoutPopupConfig {
  onCancel?: () => void;
  onClose?: () => void;
  onError?: (err: Error) => void;
  onOpen?: () => void;
  onSuccess?: (isSubscriptionPurchased: boolean) => void;
  targetPlan: CheckoutUrlPlanName;
}

export interface UseCheckoutPopupResult {
  anchorProps: AnchorProps;
  status: QueryStatus;
}

/**
 * The Stripe checkout page works as follows:
 *  - Eddy loads a URL on plan-service which then redirects to the Stripe checkout page
 *  - When the user successfully purchases a plan, the Stripe checkout page
 *    redirects to a success page on plan-service
 *  - The success page on plan-service sends a post message to window.opener
 *    indicating that the user has purchased a plan
 *
 * If the API URL is opened using an anchor tag with `target="_blank"`, then the
 * redirect from our API to Stripe will cause `window.opener` to get cleared, meaning
 * the checkout success page won't be able to send Eddy a post message.
 *
 * To get around this, Eddy first opens a "popup" (new tab) and then loads the API
 * URL. When the redirect happens using this method, `window.opener` is not cleared.
 */
export default function useCheckoutPopup(
  config: UseCheckoutPopupConfig,
): UseCheckoutPopupResult {
  const { onCancel, onClose, onError, onOpen, onSuccess, targetPlan } =
    config ?? {};

  const { userId } = useAuth();

  const [status, setStatus] = useState<QueryStatus>('idle');
  const closeRef = useRef<ClosePopup>();
  const resolvedRef = useRef(false);
  const popupRef = useRef<Window | null>(null);

  const [listenForPostMessage, stopListeningForPostMessage] =
    useCheckoutPostMessage({ onSuccess });

  // NB: we might need the backend to tell us which plan was purchased so that we
  // know when to stop polling.  This is a very naive check which satisfies the
  // use case for SPAR-20229 but won't solve more advanced use cases (e.g. upgrading
  // from one pro plan to another or downgrading from pro to free/basic, etc.).
  usePollForNewTier(status === 'success' ? 'pro' : undefined);

  const checkoutUrl = !userId
    ? undefined
    : api.createCheckoutUrl({
        application: APPLICATION_NAME,
        targetPlan,
        userId,
      });

  const handleError = useCallback(
    (err: Error) => {
      resolvedRef.current = true;
      setStatus('error');
      onError?.(err);
    },
    [onError],
  );

  const handleClose = useCallback(() => {
    if (!resolvedRef.current) {
      onCancel?.();

      // closing the popup before the task resolves is considered a success so that
      // error states aren't displayed downstream just because the user closed the
      // checkout window
      setStatus('success');
    }

    onClose?.();
    stopListeningForPostMessage();
  }, [onCancel, onClose, stopListeningForPostMessage]);

  const handleOpen: OnOpenPopup = useCallback(
    (popup, close) => {
      popupRef.current = popup;

      // store the close function so that it can be called outside of this fn
      closeRef.current = close;
      onOpen?.();
      resolvedRef.current = false;
      listenForPostMessage();
    },
    [listenForPostMessage, onOpen],
  );

  const { setPopupUrl, open: openPopup } = usePopup({
    onClose: handleClose,
    onError: handleError,
    onOpen: handleOpen,
    target: '_blank',
  });

  // react-aria workaround.  react-aria doesn't expose event.preventDefault in
  // onPress, so onClick has to be called...but it seems that react-aria might
  // be calling onClick multiple times, which creates issues if all of the logic
  // happens in onClick.  this is why there are separate onClick and onPress handlers
  const handleCtaClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
    },
    [],
  );

  const handleCtaPress = useCallback(() => {
    // instead of opening a new checkout page, refocus the existing one if it
    // exists
    if (status === 'loading') {
      popupRef.current?.focus();
      return;
    }

    setStatus('loading');
    openPopup();

    if (checkoutUrl) {
      setPopupUrl(checkoutUrl);
    }
  }, [checkoutUrl, openPopup, setPopupUrl, status]);

  return {
    anchorProps: {
      href: checkoutUrl,
      onClick: handleCtaClick,
      onPress: handleCtaPress,
    },
    status,
  };
}
