import { GradientIcon, Heading } from '@sparemin/blockhead';
import React from 'react';
import UploadLimitsMessage from 'components/UploadLimitsMessage';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface UploadLimitsContentsProps extends BaseUpsellModalProps {}

const UploadLimitsContents: React.FC<UploadLimitsContentsProps> = () => (
  <BaseUpsellModal
    title="Increase your upload limits"
    subtitle={
      <Heading level={2}>
        <UploadLimitsMessage isInline isUpsell />
      </Heading>
    }
    icon={
      <GradientIcon>
        <S.Upload />
      </GradientIcon>
    }
    footer={<BaseUpsellModalFooter modalName="EddyUploadLimitGeneral" />}
  />
);

export default UploadLimitsContents;
