import { Spacer, Text } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Container = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  flex-grow: 1;
  margin-bottom: ${({ theme }) =>
    theme.headerHeightDesktop + theme.spacing.base * 2}px;
`;

export const Content = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
  space: 3.8,
})`
  width: 400px;
  text-align: center;
`;

export const Description = styled(Text).attrs({
  variant: 'p',
})`
  font-size: 14px;
  font-weight: 500;
`;
