import { useContext } from 'react';
import ChapterContext from './ChapterContext';

export default function useChapterContext() {
  const context = useContext(ChapterContext);

  if (context === undefined) {
    throw new Error('useChapterContext must be used within ChapterProvider');
  }

  return context;
}
