import { useMemo } from 'react';
import { withFeatureProps } from '../features';
import { TranscriptTimecodesFeatureData } from '../features/types';
import TranscriptTimeRangeContext from './TranscriptTimeRangeContext';
import { TranscriptTimeRangeProviderProps } from './types';

export const TranscriptTimeRangeProviderComponent: React.FC<
  TranscriptTimeRangeProviderProps
> = ({ children, transcriptTimeRange }) => {
  return (
    <TranscriptTimeRangeContext.Provider
      value={useMemo(() => ({ transcriptTimeRange }), [transcriptTimeRange])}
    >
      {children}
    </TranscriptTimeRangeContext.Provider>
  );
};

const TranscriptTimeRangeProvider =
  withFeatureProps<TranscriptTimecodesFeatureData>(
    TranscriptTimeRangeProviderComponent,
  );

export default TranscriptTimeRangeProvider;
