import { getThemeColor, InlineTextField } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Input = styled(InlineTextField)`
  color: ${getThemeColor('d1')};
  flex-grow: 1;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: 14px;
  font-weight: normal;
`;
