import { UserSubscription } from '@sparemin/api-sdk';
import { millisToHours } from 'utils/time';

const featureSelector = (d: UserSubscription) => d.feature;

export const hasTranscriptAutomationFeatureSelector = (d: UserSubscription) =>
  featureSelector(d).eddyTranscriptAutomation.enabled ?? false;

export const isFreeTierSelector = (d: UserSubscription) =>
  d.subscribedProduct.tier === 'free';

export const isBasicTierSelector = (d: UserSubscription) =>
  d.subscribedProduct.tier === 'basic';

export const isBasicOrFreeTierSelector = (d: UserSubscription) =>
  isFreeTierSelector(d) || isBasicTierSelector(d);

export const planNameSelector = (d: UserSubscription) => d.subscribedPlan.name;

export const planMaxUploadMbSelector = (d: UserSubscription) =>
  d.planLimit?.eddyUploadMB;

export const planDurationLimitSelector = (d: UserSubscription) =>
  d.planLimit?.eddyDurationLimitHours;

const transcriptDurationUsageSelector = (d: UserSubscription) =>
  d.planUsage?.eddyTranscriptDuration;

export const planMonthlyTranscriptionRemainingDurationMillisSelector = (
  d: UserSubscription,
) => {
  const balance = transcriptDurationUsageSelector(d)?.remaining;

  // the balance can be undefined if transcriptDurationUsageSelector returns undefined.
  // if the balance is explicitly set to null, this is an indication that the user
  // has no restrictions
  return balance === null ? Infinity : balance;
};

export const planMonthlyTranscriptionDurationMillisSelector = (
  d: UserSubscription,
) => transcriptDurationUsageSelector(d)?.limit ?? 0;

export const isMonthlyTranscriptionExceededSelector = (d: UserSubscription) =>
  transcriptDurationUsageSelector(d)?.overageReached;

export const planMonthlyTranscriptionDurationHoursSelector = (
  d: UserSubscription,
) => millisToHours(planMonthlyTranscriptionDurationMillisSelector(d));

export const planMaxUploadValuesSelector = (d: UserSubscription) => ({
  maxUploadSizeMB: planMaxUploadMbSelector(d),
  maxMediaDurationHr: planDurationLimitSelector(d),
});
