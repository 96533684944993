import { getDisplayName } from 'utils/react';
import { useFeatureData } from './FeatureDataContext';

export default function withFeatureProps<TProps extends object>(
  Wrapped: React.ComponentType<TProps>,
) {
  const Wrapper = () => {
    const { data } = useFeatureData<TProps>();

    return <Wrapped {...data} />;
  };

  Wrapper.displayName = `WithFeatureProps(${getDisplayName(Wrapped)})`;

  return Wrapper;
}
