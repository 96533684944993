import { Edit } from '@sparemin/blockhead';
import { AnimatePresence } from 'framer-motion';

import { useCallback } from 'react';
import { PromoPackAssetType } from 'api';
import { useEventTracking } from 'state/eventTracking';
import { useIsOptInAsset } from '../../state';
import TopBar from '../TopBar';
import { tabPanelContentsWrapperAnimationProps } from './animation';
import BaseTabPanelContents from './BaseTabPanelContents';

import * as S from './styles';
import { TabPanelProvider } from './TabPanelContext';
import { BaseTabPanelActionButton } from './types';
import useAdjustPromoAssetsOutput from './useAdjustPromoAssetsOutput';
import useBaseTabPanel from './useBaseTabPanel';
import useRegenerateAsset from './useRegenerateAsset';

export interface BaseTabPanelProps {
  assetType?: PromoPackAssetType;
  actionButtons: BaseTabPanelActionButton[];
  allowAdjustment?: boolean;
  children: React.ReactNode;
}

const BaseTabPanel: React.FC<BaseTabPanelProps> = ({
  assetType,
  actionButtons,
  allowAdjustment = true,
  children,
}) => {
  const { trackPromoPackModalAction } = useEventTracking();
  const { adjustmentValues, colorPaletteState, onAdjustmentValueChange } =
    useAdjustPromoAssetsOutput();
  const { isRegenerating, onRegenerate } = useRegenerateAsset({
    assetType,
    adjustmentValues,
    colorPaletteState,
  });

  const {
    asset,
    isLoading,
    isError,
    isDisabled,
    isAdjusting,
    topBarHeightWithPadding,
    onTopBarHeightChange,
    toggleIsAdjusting,
  } = useBaseTabPanel(assetType);

  const isOptInAsset = useIsOptInAsset(assetType);

  if (!isAdjusting && isOptInAsset && !isRegenerating) {
    toggleIsAdjusting();
  }

  const handleRegenerate = useCallback((): void => {
    onRegenerate();
    toggleIsAdjusting();
  }, [onRegenerate, toggleIsAdjusting]);

  const handleAdjustmentClick = useCallback((): void => {
    if (assetType) {
      trackPromoPackModalAction('AdjustAsset', assetType);
    }

    toggleIsAdjusting();
  }, [assetType, toggleIsAdjusting, trackPromoPackModalAction]);

  const finalActionButtonsList = allowAdjustment
    ? [
        ...actionButtons,
        {
          key: 'adjust',
          label: 'Adjust',
          startIcon: <Edit />,
          isDisabled: isLoading,
          onPress: handleAdjustmentClick,
        },
      ]
    : actionButtons;

  return (
    <TabPanelProvider
      {...{
        adjustmentValues,
        colorPaletteState,
        onAdjustmentValueChange,
      }}
    >
      <S.Root
        layout="position"
        $height={isAdjusting ? topBarHeightWithPadding : 0}
      >
        <S.FadingContainer axis="y" backgroundColor="white">
          <S.Contents>
            {asset && (
              <TopBar
                {...{
                  asset,
                  allowAdjustment,
                  isRegenerating,
                  onTopBarHeightChange,
                  isAdjusting,
                  toggleIsAdjusting,
                }}
                actionButtons={finalActionButtonsList}
                onRegenerate={handleRegenerate}
              />
            )}

            <AnimatePresence initial={false}>
              {!isRegenerating && (
                <S.TabPanelContentsWrapper
                  {...tabPanelContentsWrapperAnimationProps}
                >
                  <BaseTabPanelContents
                    {...{
                      topBarHeightWithPadding,
                      isLoading,
                      isError,
                      isDisabled,
                      children,
                    }}
                  />
                </S.TabPanelContentsWrapper>
              )}
            </AnimatePresence>
          </S.Contents>
        </S.FadingContainer>
      </S.Root>
    </TabPanelProvider>
  );
};

export default BaseTabPanel;
