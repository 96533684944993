import { SubscriptionPlanName } from '@sparemin/api-sdk';
import mixpanel, { Dict } from 'mixpanel-browser';

import { pascalKeys } from 'utils/object';
import {
  ClearUserTrackingInfo,
  ErrorCode,
  FileTransferErrorCode,
  SetUserTrackingInfo,
} from '../types';
import { AppNameProps } from './types';

// Eddy sends data to the same Mixpanel Project as Make. In order to identify the source of events
// the AppName property should be sent with every event, rather than relying on the property being set as a super property,
// which does not reliably get overwritten when users' switch application.
function addAppNameToProperties<T extends Dict | undefined>(
  properties: T,
): T & AppNameProps {
  const appName = { appName: 'Eddy' };
  return { ...properties, ...appName };
}

export const track: typeof mixpanel.track = (
  eventName,
  properties,
  optionsOrCallback,
  callback,
) => {
  const allProperties = addAppNameToProperties(properties);

  const transformedProperties = !allProperties
    ? undefined
    : pascalKeys(allProperties);

  mixpanel.track(eventName, transformedProperties, optionsOrCallback, callback);
};

export const setUserTrackingInfo: SetUserTrackingInfo = (
  email: string,
  userId: number,
  planType: SubscriptionPlanName,
) => {
  mixpanel.identify(email);
  mixpanel.people.set({
    $email: email,
    last_login: new Date(),
    user_id: userId,
    $planType: planType,
  });

  mixpanel.people.set_once('firstLogin', new Date().toISOString());
  mixpanel.register({ planType });
};

export const clearUserTrackingInfo: ClearUserTrackingInfo = () => {
  mixpanel.reset();
};

export function isFileError(val: ErrorCode): val is FileTransferErrorCode {
  return Boolean((val as FileTransferErrorCode).type);
}

export function booleanToAffirmativeValue(val?: boolean) {
  return val ? 'Yes' : 'No';
}
