import {
  usePlanDurationLimitHours,
  usePlanMaxUploadMb,
  usePlanMonthlyDurationLimitHours,
} from 'state/pricing';

export default function useCanRenderUploadLimits() {
  const { data: maxFileUploadMb } = usePlanMaxUploadMb();
  const { data: fileDurationLimitHours } = usePlanDurationLimitHours();
  const { data: monthlyDurationLimitHours } =
    usePlanMonthlyDurationLimitHours();

  return (
    maxFileUploadMb !== undefined &&
    fileDurationLimitHours !== undefined &&
    monthlyDurationLimitHours !== undefined
  );
}
