import { useCallback } from 'react';
import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from 'react-query';
import { UpdateProjectSettingsArgs, updateProjectSettings } from 'api';
import { useAuth } from 'state/auth';
import { useTranscriptTimeRangeContext } from '../TranscriptTimeRangeContext';
import { eddyProjectsQueryKeys, eddyTranscriptQueryKeys } from './queryKeys';
import { useEditorProjectTranscript, useProjectCuid } from './useEditorProject';

export type UseUpdateProjectSettingsArgs = Omit<
  UpdateProjectSettingsArgs,
  'projectCuid'
>;

interface UseUpdateProjectSettingsOptions<TVariables, TContext>
  extends UseMutationOptions<void, unknown, TVariables, TContext> {
  awaitInvalidation?: boolean;
}

export default function useUpdateProjectSettings<
  TVariables,
  TContext = unknown,
>(
  opts?: UseUpdateProjectSettingsOptions<TVariables, TContext>,
): UseMutationResult<void, unknown, TVariables, TContext>;
export default function useUpdateProjectSettings<TContext = unknown>(
  opts?: UseUpdateProjectSettingsOptions<
    UseUpdateProjectSettingsArgs,
    TContext
  >,
): UseMutationResult<void, unknown, UseUpdateProjectSettingsArgs>;
export default function useUpdateProjectSettings<
  TVariables,
  TContext = unknown,
>(opts?: UseUpdateProjectSettingsOptions<TVariables, TContext>) {
  const { awaitInvalidation } = opts ?? {};
  const queryClient = useQueryClient();
  const { userId } = useAuth();

  const { data: projectCuid } = useProjectCuid();
  const { data: transcript } = useEditorProjectTranscript();
  const { transcriptTimeRange } = useTranscriptTimeRangeContext();
  const { startMillis, endMillis } = transcriptTimeRange ?? {};

  const defaultMutationFn = useCallback(
    async (args: UseUpdateProjectSettingsArgs) => {
      if (!projectCuid) {
        return;
      }

      await updateProjectSettings({ projectCuid, ...args });
    },
    [projectCuid],
  );

  return useMutation<void, unknown, TVariables, TContext>({
    mutationFn: (opts?.mutationFn ?? defaultMutationFn) as MutationFunction<
      void,
      TVariables
    >,
    onSettled: () => {
      const projectQueryInvalidation = queryClient.invalidateQueries(
        eddyProjectsQueryKeys.myProject(userId, projectCuid),
      );

      if (transcript?.id && transcript?.status !== 'processing') {
        queryClient.invalidateQueries(
          eddyTranscriptQueryKeys.transcript(
            userId,
            transcript?.id,
            startMillis,
            endMillis,
          ),
          { exact: true },
        );
      }

      if (awaitInvalidation) {
        return projectQueryInvalidation;
      }

      return undefined;
    },
    ...opts,
  });
}
