import React from 'react';
import { ControlledFeature } from 'pages/TranscriptEditorPage/features';
import InsertSpeakerButton from './InsertSpeakerButton';

export type InsertElementPopoverContentsProps = Record<string, never>;

const InsertElementPopoverContents: React.FC<
  InsertElementPopoverContentsProps
> = () => (
  <>
    <InsertSpeakerButton />
    <ControlledFeature feature="chapters.addChapter.contextMenuButton" />
  </>
);
export default InsertElementPopoverContents;
