import { AxiosResponse } from 'axios';
import { request } from 'api';
import { MEDIA_UPLOAD_V1_URL } from './constants';
import { DirectUploadEndpointArgs, DirectUploadResult } from './types';

export async function directUpload({
  contentType,
  filename,
  fileType,
}: DirectUploadEndpointArgs) {
  const { data } = await request.postForm<
    DirectUploadResult,
    AxiosResponse<DirectUploadResult>,
    DirectUploadEndpointArgs
    // NB: the trailing slash is necessary here, otherwise a cors error will be
    // thrown by the backend
  >(`${MEDIA_UPLOAD_V1_URL}/direct-upload/`, {
    contentType,
    filename,
    fileType,
  });

  return data;
}
