import React, { useMemo } from 'react';
import ChapterContext from './ChapterContext';
import { ChapterContextType } from './types';

export type ChapterProviderProps = React.PropsWithChildren<ChapterContextType>;

const ChapterProvider: React.FC<ChapterProviderProps> = ({
  chapterId,
  children,
}) => (
  <ChapterContext.Provider value={useMemo(() => ({ chapterId }), [chapterId])}>
    {children}
  </ChapterContext.Provider>
);

export default ChapterProvider;
