import React from 'react';
import { SuggestedClipPopover } from 'pages/TranscriptEditorPage/Transcript';
import NativeActions from './NativeActions';

export interface SuggestionPopoverProps {}

const SuggestionPopover: React.FC<SuggestionPopoverProps> = () => (
  <SuggestedClipPopover actions={<NativeActions />} />
);

export default SuggestionPopover;
