import { ControlledFeature } from './features';
import TranscriptEditorPageContents from './TranscriptEditorPageContents';

export type TranscriptEditorPageProps = {
  className?: string;
};

const TranscriptEditorPage: React.FC<TranscriptEditorPageProps> = ({
  className,
}) => {
  return (
    <ControlledFeature
      feature="transcript.timecodes.provider"
      data={{
        children: <TranscriptEditorPageContents className={className} />,
      }}
    />
  );
};

export default TranscriptEditorPage;
