import { ActionButton, Anchor, Doc, Mic, Video } from '@sparemin/blockhead';
import { useMemo } from 'react';
import { useExportFormatsOverride } from 'state/displayPref';
import { useIsIntegrator } from 'state/user';
import * as S from './styles';
import { ActionButtonsProps, ActionButtonType } from './types';
import useActionButtonsColors from './useActionButtonsColors';

export type UseActionButtonsConfig = ActionButtonsProps;

export default function useActionButtons({
  buttonsDisabled,
  projectMediaType,
  onExportAudio,
  onExportVideo,
  onExportAudiogram,
  onExportDoc,
  onExportVtt,
  onExportSrt,
  onExportPdf,
  onExportTranscriptClick,
  onExportUrlsClick,
}: UseActionButtonsConfig) {
  const { data: isIntegrator } = useIsIntegrator();
  const { data: exportFormats } = useExportFormatsOverride();

  const buttonsColors = useActionButtonsColors();

  const buttonsList = useMemo((): Record<ActionButtonType, React.ReactNode> => {
    return {
      video: (
        <ActionButton
          key="video"
          color={buttonsColors.VIDEO_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="Video"
          onPress={onExportVideo}
          variant="solid"
        >
          <S.IconWrapper size={28}>
            <Video height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      audiogram: (
        <ActionButton
          key="audiogram"
          color={buttonsColors.VIDEO_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="Audiogram"
          onPress={onExportAudiogram}
          variant="solid"
        >
          <S.IconWrapper size={33}>
            <Video height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      audio: (
        <ActionButton
          key="audio"
          color={buttonsColors.AUDIO_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="Audio"
          onPress={onExportAudio}
          variant="solid"
        >
          <S.IconWrapper size={33}>
            <Mic height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      episode: (
        <ActionButton
          key="episode"
          color={buttonsColors.AUDIO_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="Audio"
          onPress={onExportAudio}
          variant="solid"
        >
          <S.IconWrapper size={33}>
            <Mic height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      transcript: (
        <ActionButton
          key="transcript"
          color={buttonsColors.DOC_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="Transcript"
          onPress={onExportTranscriptClick}
          variant="solid"
        >
          <S.IconWrapper paddingTop={5} size={47.5}>
            <Doc height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      pdf: (
        <ActionButton
          key="pdf"
          color={buttonsColors.PDF_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="PDF"
          onPress={onExportPdf}
          variant="solid"
        >
          <S.IconWrapper paddingTop={5} size={47.5}>
            <Doc height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      docx: (
        <ActionButton
          key="doc"
          color={buttonsColors.DOC_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="DOC"
          onPress={onExportDoc}
          variant="solid"
        >
          <S.IconWrapper paddingTop={5} size={47.5}>
            <Doc height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      vtt: (
        <ActionButton
          key="vtt"
          color={buttonsColors.VTT_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="VTT"
          onPress={onExportVtt}
          variant="solid"
        >
          <S.IconWrapper paddingTop={5} size={47.5}>
            <Doc height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      srt: (
        <ActionButton
          key="srt"
          color={buttonsColors.SRT_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="SRT"
          onPress={onExportSrt}
          variant="solid"
        >
          <S.IconWrapper paddingTop={5} size={47.5}>
            <Doc height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
      urls: (
        <ActionButton
          key="urls"
          color={buttonsColors.URLS_BTN_COLOR}
          isDisabled={buttonsDisabled}
          label="URLs"
          onPress={onExportUrlsClick}
          variant="solid"
        >
          <S.IconWrapper size={34}>
            <Anchor height="100%" width="100%" />
          </S.IconWrapper>
        </ActionButton>
      ),
    };
  }, [
    buttonsColors.AUDIO_BTN_COLOR,
    buttonsColors.DOC_BTN_COLOR,
    buttonsColors.PDF_BTN_COLOR,
    buttonsColors.SRT_BTN_COLOR,
    buttonsColors.URLS_BTN_COLOR,
    buttonsColors.VIDEO_BTN_COLOR,
    buttonsColors.VTT_BTN_COLOR,
    buttonsDisabled,
    onExportAudio,
    onExportAudiogram,
    onExportDoc,
    onExportPdf,
    onExportSrt,
    onExportTranscriptClick,
    onExportUrlsClick,
    onExportVideo,
    onExportVtt,
  ]);

  const actionButtons = useMemo(() => {
    const { video, audiogram, audio, transcript, pdf, docx, vtt, srt, urls } =
      buttonsList;

    const defaultButtons = [audio];

    if (exportFormats?.length) {
      return exportFormats
        .map((format) => buttonsList[format as ActionButtonType])
        .filter(Boolean);
    }

    if (projectMediaType === 'video') {
      // Add video button at the start.
      defaultButtons.unshift(video);
    }

    if (projectMediaType === 'audio') {
      // Add audiogram button at the start.
      defaultButtons.unshift(audiogram);
    }

    if (isIntegrator) {
      return [...defaultButtons, pdf, docx, vtt, srt];
    }

    return [...defaultButtons, transcript, urls];
  }, [buttonsList, exportFormats, isIntegrator, projectMediaType]);

  return actionButtons;
}
