import {
  CHECKOUT_POSTMESSAGE_SOURCE,
  CheckoutPostMessagePayload,
} from '@sparemin/api-sdk';

export function isCheckoutMessage(
  event: MessageEvent,
): event is MessageEvent<CheckoutPostMessagePayload> {
  return event?.data.source === CHECKOUT_POSTMESSAGE_SOURCE;
}
