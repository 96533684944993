import styled from 'styled-components';
import { transparent } from 'utils/colors';

const FadingFooter = styled.div`
  background-image: ${({ theme }) => `
    linear-gradient(
      to top,
      ${theme.backgroundColorMain},
      ${transparent(theme.backgroundColorMain)}
    );
  `};
  bottom: 0;
  height: ${({ theme }) => theme.footerFadeHeight}px;
  left: 0;
  position: fixed;
  width: 100vw;
  pointer-events: none;
`;

export default FadingFooter;
