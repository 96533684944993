import { IconButton, XOutline } from '@sparemin/blockhead';
import React, { useEffect } from 'react';
import ExplorerBar from 'components/ExplorerBar';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import usePrevious from 'hooks/usePrevious';
import { SUGGESTION_ID_DATA_ATTRIBUTE } from '../Transcript/SpeakerSegment/SuggestionContainer';
import { SUGGESTED_CLIP_TOOLTIP_CLASSNAME } from '../Transcript/SpeakerSegment/SuggestionContainer/SuggestedClipTooltip';
import * as S from './styles';
import SuggestedClipsTrigger from './SuggestedClipsTrigger';
import useBrowseSuggestedClips from './useBrowseSuggestedClips';
import useSuggestedClipsExplorerKeybindings from './useSuggestedClipsExplorerKeybindings';

export interface SuggestedClipsExplorerProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const clickOutsideIgnoreSelector = `[${SUGGESTION_ID_DATA_ATTRIBUTE}],.${SUGGESTED_CLIP_TOOLTIP_CLASSNAME}`;

const SuggestedClipsExplorer: React.FC<SuggestedClipsExplorerProps> = ({
  className,
  isOpen,
  onClose,
  onOpen,
}) => {
  const { clipCount, clipIndex, focusCurrentClip, onNextClip, onPrevClip } =
    useBrowseSuggestedClips();
  const prevClipCount = usePrevious(clipCount);

  // when the clip count changes, that implies that a clip was deleted.  if the clip
  // index is still valid, then refocusing the clip index will bring the next clip
  // into view.  if the clip index is out of bounds, reset it by cycling to the next
  // clip
  useEffect(() => {
    if (clipCount !== prevClipCount && isOpen) {
      if (clipIndex < clipCount) {
        focusCurrentClip();
      } else {
        onNextClip();
      }
    }
  }, [
    clipCount,
    clipIndex,
    focusCurrentClip,
    isOpen,
    onNextClip,
    prevClipCount,
  ]);

  useSuggestedClipsExplorerKeybindings({
    isOpen,
    onClose,
    onNextClip,
    onPrevClip,
  });

  const handleTransitionEnd = useDebouncedCallback(
    () => {
      if (isOpen) {
        focusCurrentClip();
      }
    },
    { timeoutMillis: 400 },
  );

  return (
    <S.Root
      className={className}
      clickOutsideIgnoreSelector={clickOutsideIgnoreSelector}
      isOpen={isOpen}
      onClose={onClose}
      onTransitionEnd={handleTransitionEnd}
    >
      <S.SearchField
        isReadOnly
        value="Use these clips to promote your episode!"
      >
        <SuggestedClipsTrigger
          isOpen={isOpen}
          clipCount={clipCount}
          onPress={onOpen}
        />
        <S.Input />
        <S.ResultsCount position={clipIndex + 1} total={clipCount} />
        <ExplorerBar.ArrowControls onNext={onNextClip} onPrev={onPrevClip} />
        <IconButton onPress={onClose}>
          <XOutline />
        </IconButton>
      </S.SearchField>
    </S.Root>
  );
};

export default SuggestedClipsExplorer;
