import { useCallback } from 'react';

import { PlayButton as BasePlayButton } from 'components/MediaControlButton';
import { useAudioPlayer } from 'pages/TranscriptEditorPage/AudioPlayerContext';
import { useRegisterKeybinding } from 'state/hotkey';

import { usePlaybackState } from '../hooks';

export interface PlayButtonProps {}

const PlayButton: React.FC<PlayButtonProps> = () => {
  const { player } = useAudioPlayer();
  const { paused, ready } = usePlaybackState();

  const handlePress = useCallback(() => {
    player.togglePlay();
  }, [player]);

  useRegisterKeybinding(' ', handlePress);

  return (
    <BasePlayButton
      isDisabled={!ready}
      onPress={handlePress}
      paused={paused}
      tooltip="Play/Pause [spacebar]"
    />
  );
};

export default PlayButton;
