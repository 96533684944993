import { CheckoutUrlPlanName } from '@sparemin/api-sdk';
import notifyError from 'components/notification';
import { DEFAULT_UPSELL_PLAN } from 'config';
import useCheckoutPopup from 'hooks/useCheckoutPopup';
import { AnchorProps } from 'hooks/useCheckoutPopup/types';
import { useEventTracking, UpsellModal } from 'state/eventTracking';

export interface UseUpsellModalContentsConfigConfig {
  modalName: UpsellModal;
  targetPlan?: CheckoutUrlPlanName;
  errorMessage?: string;
  onSuccess?: () => void;
}

export interface UseUpsellModalContentsConfigResult {
  anchorProps: AnchorProps;
}

export default function useUpsellModalContentsConfigProps({
  modalName,
  targetPlan = DEFAULT_UPSELL_PLAN,
  errorMessage = 'Error processing checkout',
  onSuccess,
}: UseUpsellModalContentsConfigConfig): UseUpsellModalContentsConfigResult {
  const { trackUnlockThisFeature } = useEventTracking();

  const { anchorProps } = useCheckoutPopup({
    onOpen() {
      trackUnlockThisFeature({ modal: modalName });
    },
    onSuccess,
    onError() {
      notifyError({
        heading: errorMessage,
      });
    },
    targetPlan,
  });

  return {
    anchorProps,
  };
}
