import { useContext } from 'react';
import TranscriptTimeRangeContext from './TranscriptTimeRangeContext';

export default function useTranscriptTimeRangeContext() {
  const context = useContext(TranscriptTimeRangeContext);

  if (context === undefined) {
    throw new Error(
      'useTranscriptTimeRangeContext must be used within TranscriptTimeRangeProvider',
    );
  }

  return context;
}
