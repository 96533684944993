import React from 'react';
import { useEmbedPageQueryParams } from './EmbedPageQueryParamProviderRoute';
import {
  DefaultEmbedTranscriptEditorPage,
  InlineEmbedTranscriptEditorPage,
} from './lazyEditors';

export interface EmbedTranscriptEditorPageProps {}

const EmbedTranscriptEditorPage: React.FC<
  EmbedTranscriptEditorPageProps
> = () => {
  const { context } = useEmbedPageQueryParams();

  return context === 'inline' ? (
    <InlineEmbedTranscriptEditorPage />
  ) : (
    <DefaultEmbedTranscriptEditorPage />
  );
};

export default EmbedTranscriptEditorPage;
