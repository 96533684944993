import styled from 'styled-components';
import IntervalDurationButton from 'pages/TranscriptEditorPage/IntervalDurationButton';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const DurationButton = styled(IntervalDurationButton)`
  height: 32px;
  margin-right: 8px;
`;
