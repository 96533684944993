import { transparentize } from 'color2k';
import { constant, flowRight, partialRight, round } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import { TimelinePluginParams } from 'wavesurfer.js/src/plugin/timeline';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { formatDuration, millisToSec, secToMillis } from 'utils/time';
import {
  ClipRegionPlugin,
  CursorIndicatorPlugin,
  SegmentWaveSurfer,
  TimelineSegmentPlugin,
} from 'utils/wavesurfer';
import { RegionSegmentPlugin } from 'utils/wavesurfer';
import RegionResizeEventPlugin from 'utils/wavesurfer/RegionResizeEventPlugin';
import SegmentDrawer from 'utils/wavesurfer/SegmentDrawer';
import { CLIP_REGION_ID } from './constants';
import { getPlaybackStartSec, pxToSec } from './utils';

interface SurroundingSelection {
  id: number | string;
  startMillis: number;
  endMillis: number;
}

export interface UseWaveSurferConfig
  extends Pick<WaveSurferParams, 'container'> {
  audioDuration: number;
  assetUrl?: string;
  defaultClipStartSec: number;
  defaultClipEndSec: number;
  defaultPeaks: number[] | undefined;
  surroundingSelections?: SurroundingSelection[];
  timelineContainer: TimelinePluginParams['container'];
  waveEndSec: number;
  waveStartSec: number;
}

const formatTime = flowRight(
  partialRight(formatDuration, 'H:mm:ss.SSS'),
  round,
  secToMillis,
);

export default function useWaveSurfer({
  audioDuration,
  assetUrl,
  defaultClipEndSec,
  defaultClipStartSec,
  container,

  defaultPeaks,
  surroundingSelections,
  timelineContainer,
  waveEndSec,
  waveStartSec,
}: UseWaveSurferConfig) {
  const theme = useTheme();
  const wavesurferRef = useRef<SegmentWaveSurfer>();
  const [wavesurfer, setWavesurfer] = useState<SegmentWaveSurfer>();
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current && assetUrl) {
      initializedRef.current = true;

      const waveDuration = waveEndSec - waveStartSec;

      const ws = SegmentWaveSurfer.create({
        container,
        autoCenter: false,
        backend: 'MediaElement',
        backgroundColor: theme.palette.l4,
        barGap: 3,
        barWidth: 3,
        barRadius: 2,
        cursorColor: theme.palette.common.black,
        cursorWidth: 2,
        hideScrollbar: true,
        plugins: [
          RegionsPlugin.create({
            regions: [
              ...(surroundingSelections ?? []).map((w) => ({
                color: transparentize(theme.palette.actionSecondary.main, 0.5),
                end: millisToSec(w.endMillis),
                drag: false,
                resize: false,
                start: millisToSec(w.startMillis),
              })),

              {
                color: transparentize(theme.palette.d5, 0.5),
                drag: false,
                end: defaultClipEndSec,
                id: CLIP_REGION_ID,
                minLength: 0.05,
                start: defaultClipStartSec,
              },
            ],
          }),
          ClipRegionPlugin.create({
            clipRegionId: CLIP_REGION_ID,
            color: theme.palette.d5,
            maxSec: waveEndSec,
            minSec: waveStartSec,
          }),
          RegionSegmentPlugin.create({}),
          RegionResizeEventPlugin.create({}),
          TimelineSegmentPlugin.create({
            container: timelineContainer,
            fontFamily: 'Courier Prime',
            fontSize: 12,
            formatTimeCallback: formatTime,
            primaryFontColor: theme.palette.d1,
            timeInterval: constant(waveDuration / 4),
          }),
          CursorIndicatorPlugin.create(),
        ],
        progressColor: theme.palette.actionPrimary.main,
        // the DefinitelyTyped types for `renderer` are wrong and cannot be
        // overridden with declaration merging
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderer: SegmentDrawer,
        waveColor: theme.palette.actionPrimary.main,
      });

      const initialize = () => {
        ws.setRange(waveStartSec, waveEndSec);
        // As it is required to move playhead by the width of the handle, an
        // adjusted start sec is required. For that, handle width is transformed
        // to seconds and start sec adjusted.
        // For getting the corred pixels per second value, range is set beforehand.
        const startSec =
          waveStartSec +
          pxToSec(ClipRegionPlugin.HANDLE_WIDTH, ws.getPixelsPerSecond());
        ws.seekTo(
          getPlaybackStartSec(
            defaultClipStartSec,
            defaultClipEndSec,
            startSec,
            waveEndSec,
          ) / audioDuration,
        );
      };

      if (ws.isReady) {
        initialize();
      } else {
        ws.once('ready', initialize);
      }

      ws.load(assetUrl, defaultPeaks, 'auto', audioDuration);

      wavesurferRef.current = ws;
      setWavesurfer(ws);
    }
  }, [
    assetUrl,
    audioDuration,
    container,
    defaultClipEndSec,
    defaultClipStartSec,
    defaultPeaks,
    surroundingSelections,
    theme.palette.actionPrimary.main,
    theme.palette.actionSecondary.main,
    theme.palette.common.black,
    theme.palette.d1,
    theme.palette.d5,
    theme.palette.l4,
    timelineContainer,
    waveEndSec,
    waveStartSec,
  ]);

  useEffect(
    () => () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        initializedRef.current = false;
        setWavesurfer(undefined);
      }
    },
    [],
  );

  return { wavesurfer };
}
