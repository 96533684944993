import { ButtonProps } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export type SuggestedClipSecondaryActionButtonProps = ButtonProps & {
  'aria-label': string;
};

const SuggestedClipSecondaryActionButton = React.forwardRef<
  HTMLButtonElement,
  SuggestedClipSecondaryActionButtonProps
>(({ color = 'tertiary', ...props }, ref) => {
  return <S.SecondaryButton {...props} color={color} ref={ref} />;
});

export default SuggestedClipSecondaryActionButton;
