import React, { useMemo } from 'react';
import EmbedPageQueryParamContext from './EmbedPageQueryParamContext';
import { EmbedPageQueryParamProviderContextType } from './types';

export type EmbedPageQueryParamProviderProps =
  React.PropsWithChildren<EmbedPageQueryParamProviderContextType>;

const EmbedPageQueryParamProvider: React.FC<
  EmbedPageQueryParamProviderProps
> = ({ children, context, margin, transcriptTimeRange }) => (
  <EmbedPageQueryParamContext.Provider
    value={useMemo(
      () => ({ context, margin, transcriptTimeRange }),
      [context, margin, transcriptTimeRange],
    )}
  >
    {children}
  </EmbedPageQueryParamContext.Provider>
);

export default EmbedPageQueryParamProvider;
